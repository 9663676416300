import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCategoryFilters, setActiveCategoryFilter } from "redux/actions";
import { RootState } from "redux/types";
import CategoryFilter from "./components/CategoryFilter";
import { CategoryFilterType } from "helpers/enums";
import { CategoryFilterData } from "helpers/interfaces";
import { Wrapper } from "./styles";

export function CategoryFilters() {
  const categoryFilters = useSelector(
    (state: RootState) => state.categoryFilter.categoryFilters
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (categoryFilters.length <= 0) {
      dispatch(getCategoryFilters());
    }
  }, []);

  const updatCategoryState = (id: CategoryFilterType) => {
    dispatch(setActiveCategoryFilter(id));
  };

  return (
    <Wrapper>
      {categoryFilters.map(
        (categoryFilter: CategoryFilterData, index: number) => (
          <CategoryFilter
            categoryFilter={categoryFilter}
            updateState={updatCategoryState}
            key={index}
          />
        )
      )}
    </Wrapper>
  );
}
