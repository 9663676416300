import { Area, AreaChart, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Surface, Symbols, Tooltip, XAxis, YAxis } from "recharts";
import PropTypes from "prop-types";
import { Data } from "../Data";
import { DownloadsCharsProp } from "./interface";
import { useCallback, useEffect, useState } from "react";
import { getColor } from "../Charts";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";
import { CostumizedToolTip, Date, DateBox, DateLine, LegendDiv, LegendStores, StoreName, StoresSpan } from "./styles";



export function DownloadsChart(props: DownloadsCharsProp) {

  const data02 = props.data

  const gainerName = useSelector((state: RootState) => state.appDownloads.topGainerLoser.topGainerLoser.gainer);
  const loserName = useSelector((state: RootState) => state.appDownloads.topGainerLoser.topGainerLoser.loser);
  const stores = useSelector((state: RootState) => state.stores.stores)
  const allStores = useSelector((state: RootState) => state.stores.stores)
  const [request, setRequest] = useState(false);
  const storesImg = Object.values(allStores).map((store: any) => {
    return store.image
  })



  function getStoreImg (name:string):string {

    const temp = Object.keys(allStores).map((store:any, index:number) => {
      if(store == name) {
        return index
      }
      else {
        return -1
      }
    })
    const temp2 = temp.filter((item:number) => item > -1)
    return String(storesImg[temp2[0]])
  }



  const [storesName, setstoresName] = useState([""]);
  if (storesName.length == 1) {
    storesName.pop();
  }

  const [isHide, setIsHide] = useState([false]);
  if (isHide.length == 1) {
    isHide.pop();
  }

  const [haveData, setHaveData] = useState([false]);
  if (haveData.length == 1) {
    haveData.pop();
  }

  Object.keys(stores).map((store, index) => {
    if (isHide.length < Object.keys(stores).length) {
      storesName.push(store)
      isHide.push(false)
      haveData.push(false)
    }
  })

  useEffect(() => {
    if (request) {
      setRequest(false)
    }
  }, [request])


  const handleClick = (a: any) => {
    console.log(a);
    storesName.map((store, index) => {
      if (store == a) {
        isHide[index] = !isHide[index]
        setIsHide(isHide);
      }
    })
    setRequest(true)
  }

  const checkData = (store: string) => {
    Object.keys(data02).map((s: string, index) => {


      Object.entries(data02[index]).map((entry) => {
        if (entry[0] == store) {
          if (entry[1] != "NA") {
            haveData[Object.keys(stores).findIndex(a => a == store)] = true;
          }
        }
      })

    })
  }
  Object.keys(stores).map((store) => {
    checkData(store)
  })


  const CustomTooltip = ({ payload, label }: any) => {
    if (payload && payload.length) {
      return (
        <CostumizedToolTip>
          <DateBox>
            <Date>{label}</Date>
          </DateBox>
          <DateLine />
          {
            payload.map((entry: any, index: number) => <StoreName color={payload[index].color}>{`${payload[index].dataKey}: ${payload[index].payload[payload[index].dataKey]}`}</StoreName>)
          }
        </CostumizedToolTip>

      );
    }

    return null;
  };


  const CustomLegend = ({ payload }: any) => {
    return (
      <LegendDiv>
        {payload.map((entry: any, index: number) => {
          const { dataKey, color } = entry;

          return (
            <StoresSpan
              onClick={() => handleClick(dataKey)}
              color={color}
            >
              <img src={getStoreImg(dataKey)} width={16} height={16} />
              <LegendStores hide={isHide[Object.keys(stores).findIndex(a => a == dataKey)]}>{dataKey}</LegendStores>
            </StoresSpan>
          );
        })}
      </LegendDiv>
    );
  }





  switch (props.filter) {

    case "Top Gainer":
      const gurl = "url(#" + gainerName + ")"
      return (
        <ResponsiveContainer height='100%' width='100%'>
          <AreaChart width={730} height={300} data={data02}
            margin={{ top: 10, right: 20, left: 0, bottom: 0 }}>
            <defs>
              <linearGradient id={gainerName} x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={getColor(gainerName)} stopOpacity={0.8} />
                <stop offset="95%" stopColor={getColor(gainerName)} stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="date" tick={{ fontSize: 10 }} />
            <YAxis tick={{ fontSize: 10 }} />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Area type="monotone" dataKey={gainerName} stroke={getColor(gainerName)} fillOpacity={1} fill={gurl} connectNulls={true} />

          </AreaChart>
        </ResponsiveContainer>
      );
    case "Top Loser":
      const lurl = "url(#" + loserName + ")"
      return (
        <ResponsiveContainer height='100%' width='100%'>
          <AreaChart width={730} height={300} data={data02}
            margin={{ top: 10, right: 20, left: 0, bottom: 0 }}>
            <defs>
              <linearGradient id={loserName} x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={getColor(loserName)} stopOpacity={0.8} />
                <stop offset="95%" stopColor={getColor(loserName)} stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="date" tick={{ fontSize: 10 }} />
            <YAxis tick={{ fontSize: 10 }} />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Area type="monotone" dataKey={loserName} stroke={getColor(loserName)} fillOpacity={1} fill={lurl} connectNulls={true} />
          </AreaChart>
        </ResponsiveContainer>
      );

    default:

      return (
        <ResponsiveContainer height='100%' width='100%'>
          <LineChart width={730} height={300} data={data02}
            margin={{ top: 0, right: 20, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" tick={{ fontSize: 10 }} />
            <YAxis tick={{ fontSize: 10 }} />
            <Tooltip isAnimationActive={false} wrapperStyle={{ zIndex: 1000, outline: "none" }} content={CustomTooltip} />
            <Legend onClick={handleClick} content={CustomLegend} wrapperStyle={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center'
            }} />
            {
              Object.keys(stores).map((store) =>
                haveData[Object.keys(stores).findIndex(a => a == store)] && store != "nineapps" ? <Line type="monotone" dataKey={store} stroke={getColor(store)} connectNulls={true} hide={isHide[Object.keys(stores).findIndex(a => a == store)]} /> : <div></div>
              )
            }
          </LineChart>
        </ResponsiveContainer>
      );
  }



}