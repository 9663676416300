import {
  GET_CATEGORY_FILTERS,
  SET_CATEGORY_FILTER_ACTIVE,
} from "redux/CONSTANTS";
import { categoryFilterReducerInitialState } from "redux/initialState";

const categoryFilterReducer = (
  state = categoryFilterReducerInitialState,
  action: any
) => {
  switch (action.type) {
    case GET_CATEGORY_FILTERS:
      return {
        ...state,
        categoryFilters: action.payload.categoryFilters,
        active: action.payload.active,
      };

    case SET_CATEGORY_FILTER_ACTIVE:
      return {
        ...state,
        active: action.payload,
      };

    default:
      return state;
  }
};

export default categoryFilterReducer;
