import React from "react";
import SkeletonData from "components/SkeletonData";
import { Wrapper } from "./styles";

export function Categories() {
  return (
    <Wrapper>
      <SkeletonData width={64} />
      <SkeletonData width={55} />
      <SkeletonData width={55} />
    </Wrapper>
  );
}
