import React from "react";
import Spinner from "components/Spinner";
import { Wrapper } from "./styles";

export function SpinnerContainer() {
  return (
    <Wrapper>
      <Spinner />
    </Wrapper>
  );
}
