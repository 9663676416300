import React from "react";
import LeftContainer from "./components/LeftContainer";
import RightContainer from "./components/RightContainer";
import { Wrapper } from "./styles";

export function About() {
  return (
    <Wrapper>
      <LeftContainer />
      <RightContainer />
    </Wrapper>
  );
}
