import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Store } from "helpers/Stores";
import Row from "./components/Row";
import { ChartRowsPropData } from "./interface";

export function Rows(props: ChartRowsPropData) {
  return (
    <Fragment>
      {props.stores.map((store: Store, index: number) => {
        return (
          <Row
            key={index}
            store={store}
            index={index + 1}
            showNumbers={props.showNumbers}
          />
        );
      })}
    </Fragment>
  );
}

Rows.propTypes = {
  stores: PropTypes.array.isRequired,
  showNumbers: PropTypes.bool.isRequired,
};
