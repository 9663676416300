import React from "react";
import Data from "./components/Data";

export function Row() {
  return (
    <tr>
      <Data format="short" />
      <Data format="medium" />
      <Data format="long" />
    </tr>
  );
}
