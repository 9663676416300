import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";
import Store from "./components/Store";

export function Stores() {
  const stores = useSelector((state: RootState) => state.stores.stores);

  return (
    <Fragment>
      {Object.keys(stores).map((storeName: string, index: number) => (
        <th key={index}>
          <Store name={storeName} image={stores[storeName].image} />
        </th>
      ))}
    </Fragment>
  );
}
