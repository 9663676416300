import React from "react";
import { useSelector } from "react-redux";
import SimpleHead from "./components/SimpleHead";
import CategoriesHead from "./components/CategoriesHead";
import TopAppsHead from "./components/TopAppsHead";
import { FilterType } from "helpers/enums";
import { RootState } from "redux/types";
import { FilterData } from "helpers/interfaces";

export function Head() {
  const filters = useSelector((state: RootState) => state.filter.filters);
  const activeFilterId = useSelector((state: RootState) => state.filter.active);

  const activeFilter = () => {
    const active = filters.find(
      (filter: FilterData) => filter.id === activeFilterId
    );

    if (!active) {
      return filters[0];
    }

    return active;
  };

  const displayHeader = () => {
    switch (activeFilterId) {
      case FilterType.ALL_APPS:
        return <SimpleHead activeFilter={activeFilter()} />;

      case FilterType.CATEGORIES:
        return <CategoriesHead activeFilter={activeFilter()} />;

      case FilterType.TOP_APPS:
        return <TopAppsHead activeFilter={activeFilter()} />;

      default:
        return <SimpleHead activeFilter={activeFilter()} />;
    }
  };

  return <thead>{displayHeader()}</thead>;
}
