import styled from "styled-components";
import { ScreenSize } from "pages/CONTANTS";

export const Wrapper = styled.div`
  flex: 0.7;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  background-color: var(--white);
  color: var(--blue);
  margin-left: 8px;
  border-radius: 18px;
  height: 100%;

  @media (max-width: ${ScreenSize.TABLET}px) {
    margin-left: 0;
    margin-top: 8px;
    padding: 24px 24px 48px 24px;
    height: initial;
  }
`;

export const Title = styled.h1`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 9px;

  @media (max-width: ${ScreenSize.TABLET}px) {
    margin-bottom: 17px;
  }
`;

export const TextWrapper = styled.div`
  margin-bottom: 17px;

  @media (max-width: ${ScreenSize.TABLET}px) {
    margin-bottom: 19px;
  }
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  margin-bottom: 30px;

  @media (max-width: ${ScreenSize.TABLET}px) {
    margin-bottom: 25px;
  }
`;

export const BoldText = styled.p`
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  margin-top: 60px;

  @media (max-width: ${ScreenSize.TABLET}px) {
    margin-top: 30px;
  }
`;

export const Email = styled.span`
  text-decoration: underline;
`;
