import axios from "axios";

const barometerApi = axios.create();

barometerApi.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
barometerApi.defaults.timeout = 25000;
barometerApi.defaults.headers.common[
  "Authorization"
] = `Bearer ${process.env.REACT_APP_BACKEND_TOKEN}`;


export default barometerApi;
