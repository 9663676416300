import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import DetailsView from "./DetailsView";
import { RootState } from "redux/types";
import { DetailsParams } from "./interface";
import { ParamCategoryType, ParamFilterType } from "helpers/enums";
import { getStores, resetPackagesDataRequest } from "redux/actions";
import SearchBar from "components/SearchBar/SearchBar";

export function DetailsContainer() {
  const { filter, category } = useParams<DetailsParams>();
  const error = useSelector((state: RootState) => state.apps.error);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStores());

    return () => {
      dispatch(resetPackagesDataRequest());
    };
  }, []);

  return (
    <div>
      {error ? (
        <div>Error</div>
      ) : (
        <div>
        <DetailsView
          filter={filter as ParamFilterType}
          category={category as ParamCategoryType}
        />
        </div>
      )}
    </div>
  );
}
