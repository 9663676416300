import { searchReducerInitialState } from "redux/initialState";
import {
    CANCEL_CLEAN,
    CLEAN_SEARCH,
    CONTINUE_QUERY,
    SET_QUERY
} from "../CONSTANTS";

const searchReducer = (state = searchReducerInitialState, action:any) => {
    switch (action.type) {
        case SET_QUERY: 
            return {
                ...state,
                query:action.payload,
                append:false
            }


        case CONTINUE_QUERY:
            return {
                ...state,
                append:true,
            }
            
        case CLEAN_SEARCH:
            return {
                ...state,
                toClean:true,
            }

        case CANCEL_CLEAN:
            return {
                ...state,
                toClean:false,
            }
                
        default: return state;
    }
} 
export default searchReducer;
