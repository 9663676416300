import React from "react";
import Rows from "./components/Rows";
import { TBody } from "./styles";

export function Body() {
  return (
    <TBody>
      <Rows />
    </TBody>
  );
}
