import styled from "styled-components";
import { ScreenSize } from "pages/CONTANTS";

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const Tag = styled.p`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  margin-top: 21px;
  margin-bottom: 6px;

  @media (max-width: ${ScreenSize.TABLET}px) {
    margin-top: 16px;
    margin-bottom: 11px;
  }
`;
