import React, { useRef, useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";
import { BarUnitPropData } from "./interface";
import { UnitBar, Number, Tag } from "./styles";
import Tooltip from "components/Tooltip";
import { numberFormat } from "helpers/numbers";

export function Unit(props: BarUnitPropData) {
  const unitBarRef = useRef<HTMLDivElement>();
  const [unitBarWidth, setUnitBarWidth] = useState<number>();

  const numberRef = useRef<HTMLDivElement>();
  const [numberWidth, setNumberWidth] = useState<number>();

  useLayoutEffect(() => {
    if (props.showNumbers) {
      if (numberRef.current) {
        setNumberWidth(numberRef.current.offsetWidth);
      }

      if (unitBarRef.current) {
        setUnitBarWidth(unitBarRef.current.offsetWidth);
      }
    }
  }, [props.showNumbers]);

  return (
    <UnitBar
      percentage={props.data.percentage}
      color={props.color}
      ref={unitBarRef}
    >
      <Tag>
        <Tooltip data={numberFormat(props.data.absolute)} />
      </Tag>

      <Number
        ref={numberRef}
        percentage={props.data.percentage}
        showNumbers={props.showNumbers}
        color={props.color}
        width={numberWidth}
        unitBarWidth={unitBarWidth}
      >
        {numberFormat(props.data.absolute)}
      </Number>
    </UnitBar>
  );
}

Unit.propTypes = {
  data: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  showNumbers: PropTypes.bool.isRequired,
};
