import React from "react";
import PropTypes from "prop-types";
import Logo from "./components/Logo";
import Bar from "./components/Bar";
import { Index, LogoCell } from "./styles";

export function Row(props: any) {
  return (
    <tr>
      <Index>{props.index}</Index>
      <LogoCell>
        <Logo name={props.store.name} image={props.store.image} />
      </LogoCell>
      <td>
        <Bar store={props.store} showNumbers={props.showNumbers} />
      </td>
    </tr>
  );
}

Row.propTypes = {
  showNumbers: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  store: PropTypes.object.isRequired,
};
