import React from "react";
import PropTypes from "prop-types";
import { Container } from "./styles";
import { SkeletonDataPropData } from "./interface";

export function SkeletonData(props: SkeletonDataPropData) {
  return <Container width={props.width}></Container>;
}

SkeletonData.propTypes = {
  width: PropTypes.number.isRequired,
};
