import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    margin-right: 8px;
  }
`;

export const Name = styled.p`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: var(--mediumGray);
  white-space: nowrap;
`;
