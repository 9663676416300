import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

export const Separator = styled.div`
  background: var(--lighterNegro);
  height: 18px;
  width: 1px;
  margin: 0 16px;
`;
