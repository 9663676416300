import React, { useState } from "react";
import PropTypes from "prop-types";
import { TitleParams, TitlePropData } from "./interface";
import { ParamFilterType } from "helpers/enums";
import { AppImage, AppImageSkel, Header, HeaderGroup, HeaderSkel, SubHeader, SubHeaderSkel } from "./styles";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";
import { useParams } from "react-router-dom";

export function Title(props: TitlePropData) {

  const packages = useSelector((state: RootState) => state.apps.packages);
  const { packageName } = useParams<TitleParams>();
  
  

  const img = packages.map((app: any, index: number) => (app.info.img )) 
  const name =  packages.map((app: any, index: number) => ( app.info.name ))
  const pName = packages.map((app: any, index: number) => ( app.package_name ))
  
  
  if (pName != "") {
    return(
      <HeaderGroup>
        <AppImage src={String(img)} alt={name} width="64" height="64"/>
        <h1>
          <Header>{name}</Header>
          <SubHeader>{pName}</SubHeader> 
        </h1>
      </HeaderGroup>
      
    );
  }
  else {
    return(
      <HeaderGroup>
      <AppImageSkel/>
      <div>
        <HeaderSkel/>
        <SubHeaderSkel/>
      </div>
    </HeaderGroup>
    )
  }
}

Title.propTypes = {
  filter: PropTypes.string.isRequired,
  category: PropTypes.string,
};
