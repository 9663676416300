import { PER_PAGE } from "helpers/CONTANTS";
import { CategoryType, ParamCategoryType } from "helpers/enums";
import {
  getPackagePercentageByCategoryData,
  getPackagesByCategoryData,
} from "services";
import { QueryParam } from "services/interface";
import {
  FETCHING_APPS_DATA,
  FETCH_APPS_ERROR,
  CATEGORIES_APPS_PACKAGE_AND_PERCENTAGE_DATA,
  CATEGORIES_APPS_PACKAGE_DATA,
  CATEGORIES_APPS_PACKAGE_DATA_APPEND,
} from "../CONSTANTS";

export const getCategoriesAppsPercentageAndData = (id: CategoryType) => {
  const percentageQueryParams: QueryParam[] = [new QueryParam("category", id)];
  const categoryDataQueryParams: QueryParam[] = [
    new QueryParam("category", id),
    new QueryParam("page", "1"),
    new QueryParam("per_page", PER_PAGE.toString()),
  ];

  return (dispatch: any) => {
    dispatch({
      type: FETCHING_APPS_DATA,
    });

    Promise.all([
      getPackagePercentageByCategoryData(percentageQueryParams),
      getPackagesByCategoryData(categoryDataQueryParams),
    ])
      .then((res: any) => {
        dispatch({
          type: CATEGORIES_APPS_PACKAGE_AND_PERCENTAGE_DATA,
          payload: {
            percentages: res[0].percentages,
            totalApps: res[1].total_apps,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_APPS_ERROR,
          payload: error,
        });
      });
  };
};

export const getCategoriesAppsData = (
  id: ParamCategoryType,
  page: number,
  append:boolean = false, 
  perPage: number = PER_PAGE
) => {
  const queryParams: QueryParam[] = [
    new QueryParam("category", id),
    new QueryParam("page", page.toString()),
    new QueryParam("per_page", perPage.toString()),
  ];

  return (dispatch: any) => {
    dispatch({
      type: FETCHING_APPS_DATA,
    });

    getPackagesByCategoryData(queryParams)
      .then((res: any) => {
          dispatch({
            type: append ? CATEGORIES_APPS_PACKAGE_DATA_APPEND : CATEGORIES_APPS_PACKAGE_DATA,
            payload: {
              packages: res.packages,
              totalPages: res.total_pages,
            },
          });
        })
      .catch((error) => {
        dispatch({
          type: FETCH_APPS_ERROR,
          payload: error,
        });
      });
  };
};
