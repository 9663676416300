import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
`;

export const Container = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 12px;
`;

export const TableData = styled.table`
  border-collapse: collapse;
  width: 100%;
  white-space: nowrap;
  table-layout: fixed;
`;
