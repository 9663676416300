import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 9px 48px;
  color: var(--white);
  border: 1px solid var(--lightestBlue);
  border-radius: 48px;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;

  &:hover {
    cursor: pointer;
  }
`;
