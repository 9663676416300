import { postEmail } from "services";
import { QueryParam } from "services/interface";
import {
  POSTING_SUBSCRIPTION_DATA,
  POST_SUBSCRIPTION_ERROR,
  EMAIL_SUBSCRIPTION,
} from "../CONSTANTS";

export const postEmailSubscription = (email: string) => {
  const queryParams: QueryParam[] = [new QueryParam("email", email)];

  return (dispatch: any) => {
    dispatch({
      type: POSTING_SUBSCRIPTION_DATA,
    });

    postEmail(queryParams)
      .then((res: any) => {
        dispatch({
          type: EMAIL_SUBSCRIPTION,
          payload: {
            subscribed: res.subscribed,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: POST_SUBSCRIPTION_ERROR,
          payload: error.message,
        });
      });
  };
};
