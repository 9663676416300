import styled from "styled-components";

export const Wrapper = styled.div`
display:flex;
flex-direction:row;
position: relative;
top:20px;
  width: 100%;
  justify-content:space-between;
`;

export const Container = styled.div`
  width: 100%;
  padding-bottom: 12px;
`;


export const StoreVersion = styled.div`
  display: flex;
  font-size: 20px;
  height:90px;
  width:130px;
  margin-right:1rem;
  flex-shrink:0;
  flex-direction:column;
  `
export const StoreName = styled.span`
  font-size: 20px;
  color:black;

`
export const StoresContainer = styled.div`
display: flex;
gap:70px;
height: ${props => props.isExpanded == true ? "fit-content" : "90px"};
align-items:center;
width:90%;
flex-direction:row;
flex-wrap: wrap;
overflow: hidden;

`
export const Versions = styled.span`
font-size: 10px;
line-height: 14px;
white-space: nowrap;
text-align: left;
padding: 9px 0;
` 
export const ButtonBox = styled.div`
display:flex;
flex-direction:column;
height: auto;
width:fit-content;
justify-content:flex-end;
align-items:stretch;
padding-bottom:58px;
`
export const StoresSkel = styled.div`
position: relative;
width: 80px;
height: 32px;

background: #E8E8E8;`
