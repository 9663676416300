import { style } from "@material-ui/system";
import styled from "styled-components";

export const ChartBox = styled.div`
display: flex;
align-items: center;
box-sizing: border-box;
flex-direction: column;
position: relative;
width: 100%;
height: 100%;
justify-content: ${props => props.skel == true ? "space-around" : ""};


/* White */

background: #FFFFFF;
/* Grey light */

border-bottom: 1px solid #EBEBEB;
border-radius: 8px 8px 0px 0px;

padding-top:2rem;

`;

export const ChartBox2 = styled.div`
display: flex;
align-items: center;
box-sizing: border-box;
flex-direction: column;
position: relative;
width: 100%;
height: 100%;
justify-content: ${props => props.skel == true ? "space-around" : ""};


/* White */

background: #FFFFFF;

/* Grey light */

border-bottom: 1px solid #EBEBEB;
border-radius: 8px 8px 0px 0px;

padding-top:1rem;




`;

export const BigBox = styled.div`
display: flex;
align-items: center;
box-sizing: border-box;
flex-direction: column;
position: relative;
width: ${props => props.mobile == true ? "100%" : "50%"};
height: 490px;


/* White */

background: #FFFFFF;
/* Grey light */

border: 1px solid #EBEBEB;
border-radius: 8px;


`;

export const BottomBoxes = styled.div`

display: flex;
align-items: center;
flex-direction: row;
position: relative;
width: 100%;
height: 80px;
justify-content: space-evenly;
border-radius: 8px;


/* White */

background: #FFFFFF;

`

export const VerticalLine = styled.div`
box-sizing: border-box;

position: relative;
width: 0px;
height: 100%;

/* Grey light */

border: 1px solid #EBEBEB;
`

export const BottomSubDiv = styled.div`
display: flex;
align-items: center;
flex-direction: column;
justify-content:center;
position: relative;
width: 50%;
height: 100%;


/* White */

background: #FFFFFF;
gap:7px;
border-radius: 8px;


`

export const AppTxt = styled.div`

/* Small Text/Regular - XXS */
display:flex;
align-items: center;
justify-content: center;
gap: 10px;
margin-bottom:5px;
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 14px;
/* identical to box height, or 140% */


/* Barometer */

color: #272846;`

export const StyledSubDivTxt = styled.h1`
/* Medium/Medium - M */

font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */


text-align: center;

/* Barometer */

color: #272846;
`

export const ChartsBox = styled.div`
margin-top:25px;
margin-bottom:50px;
justify-content: center;
position: relative;
height: 100%;
display: flex;
align-items:center;
gap: 35px;
width:100%;
margin-top:40px;
flex-direction: ${props => props.mobile == true ? "column" : "row"};
`;

export const Title = styled.h1`
psition:relative;
/* Small Text/Regular - XS */

font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 133% */



color: #272846;
`;



export const StyledButton = styled.button`
position: relative;
width: 79px;
height: 100%;
min-height:18px;

border:none;

background: ${props => props.active == true ? "#272846" : "#ffffff"};
border-radius: 18px;
color:  ${props => props.active == true ? "white" : "black"};
outline: ${props => props.active == true ? "" : "1px solid #EBEBEB"};
cursor: ${props => props.active == true ? "" : "pointer"};
/* Add a light grey background on mouse-over */
    :hover {
        background-color: ${props => props.active == true ? "" : "#ddd"};
    }


font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 14px;
`


export const ButtonDiv = styled.div`
align-self: flex-start;
/* Auto layout */
position: relative;
margin-top:0.7rem;
margin-bottom:0.5rem;
display: flex;
flex-direction: row;
align-items: center;
padding: 7px 2.5rem;
gap: 8px;
width:100%;
height:fit-content;
`;

export const Filter = styled.h1`
position: relative;
width: 38px;
height: 14px;

/* Small Text/Regular - XXS */

font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 14px;
/* identical to box height, or 140% */


color: #B6B7C1;`


export const Box = styled.div`
justify-content: flex-start;
position: relative;
width: 100%;
height: 70px;
margin-left:1.5rem;
margin-top:-1.5rem;
display:flex;
flex-direction: column;


`


export const MiniBox = styled.div`
position: relative;
width: 168px;
height: 80px;
display:flex;
align-items:center;
flex-direction: column;
/* White */

background: #FFFFFF;
/* Grey light */

border: 1px solid #EBEBEB;
border-radius: 8px;`


export const BoxLine = styled.div`
/* Line 15 */


position: relative;
width: 167px;
height: 0px;

/* Grey light */

border: 1px solid #EBEBEB;`

export const SubBox = styled.h1`
position: relative;
width: 150px;
height: 39px;
/* Small Text/Regular - XXS */

font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 14px;
/* identical to box height, or 140% */

display:flex;
align-items:center;
justify-content:space-between;


/* Grey medium */

color: #B3B3B3;


/* White */

`

export const ChartSkel = styled.div`
position: relative;
width: 90%;
height: 70%;
background: #E8E8E8;
border-radius: 8px;
margin-bottom: 30px;
`

export const TitleSkel = styled.div`
position: relative;
width: 40%;
height: 16px;

background: #E8E8E8;`