import styled from "styled-components";

export const THead = styled.thead`
  color: var(--mediumNegro);
`;

export const TableRow = styled.tr`
  th {
    position: relative;
    padding-bottom: 16px;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    text-align: left;
    width: 300px;

    &:first-child {
        width: 50px;
    }
    

    ::after {
        content: "";
        display: block;
        width: 100%;
        background-color: var(--mediumNegro);
        height: 0.5px;
        opacity: 0.2;
        position: absolute;
        top: 33px;
    }
    }
  }
`;
