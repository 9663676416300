import styled from "styled-components";

export const Container = styled.div`
  height: 8px;
  background-color: var(--lightestGray);

  ${(props) =>
    props.format === "short" &&
    `
        width: 16px;
        margin-right: 8px;
      `}

  ${(props) =>
    props.format === "medium" &&
    `
    width: 88px;
    margin-right: 16px;
    `}

    ${(props) =>
    props.format === "long" &&
    `
        width: 100%;
        `}
`;
