import styled from "styled-components";

export const TBody = styled.tbody`
  tr:first-child {
    td {
      padding-top: 16px;
    }
  }

  td {
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    white-space: nowrap;
    text-align: left;
    padding: 9px 0;
  }
`;
