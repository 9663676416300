import styled from "styled-components";

export const HeaderGroup = styled.h1`
position: relative;
width: 500px;
height: 90px;
display:flex;
align-items:center;




`;

export const Header = styled.h1`
position: relative;
width: 87px;
height: 28px;
margin-left: 1rem;

/* Large/Medium - L */

font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 28px;
/* identical to box height, or 140% */
text-overflow: ellipsis;
white-space: nowrap;


color: #272846;
`;

export const SubHeader = styled.h1`

position: relative;
width: 80px;
height: 16px;
margin-left: 1rem;

/* Small Text/Regular - XS */

font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 16px;
/* identical to box height, or 133% */


/* Grey */

color: #6F6F6F;
`;

export const AppImage = styled.img`
    

    border-radius:8px;
    overflow:hidden;
`;

export const AppImageSkel = styled.div`
    position: relative;
    width: 64px;
    height: 64px;

    background: #E8E8E8;
    border-radius: 8px;`

export const HeaderSkel = styled.div`
position: relative;
width: 104px;
height: 16px;
margin-left: 1rem;

background: #E8E8E8;`

export const SubHeaderSkel = styled.div`

position: relative;
width: 64px;
height: 8px;
margin-left: 1rem;
margin-top:0.5rem;

background: #E8E8E8;`
