import React from "react";
import PoweredLogo from "components/PoweredLogo";
import { Wrapper, Container, Title, Text } from "./styles";

export function LeftContainer() {
  return (
    <Wrapper>
      <Container>
        <Title>About the project</Title>
        <Text>
          This information compiled by the App Store Barometer is obtained from
          sources made public by the app stores themselves and other relevant
          public sources in the app distribution ecosystem.
        </Text>
        <Text>
          The apps listed are deemed to be the most relevant to the users in
          terms of downloads and popularity, and these are included taking into
          account several criteria.
        </Text>
        <Text>
          The criteria by which we decide if an app is updated or not is based
          on its version name and version code. This updated percentage is not
          related to the apps being distributed but to the total number of
          listed apps.
        </Text>
      </Container>

      <PoweredLogo></PoweredLogo>
    </Wrapper>
  );
}
