import React from "react";
import CatappultLogo from "assets/images/catappult_logo.svg";
import Image from "components/Image";
import { Wrapper, Text } from "./styles";

export function PoweredLogo() {
  return (
    <Wrapper>
      <Text>Powered by</Text>
      <Image src={CatappultLogo} alt="Catappult Logo" />
    </Wrapper>
  );
}
