import SearchBar from "components/SearchBar/SearchBar";
import useViewport from "hooks/viewport/useViewport";
import { ScreenSize } from "pages/CONTANTS";
import Logo from "./components/Logo";
import { Wrapper, LogoWrapper, Title } from "./styles.js";

export function Navbar() {
  const { width } = useViewport();

  const mobile = width <=ScreenSize.TABLET ? true : false

  return (
    <Wrapper mobile={mobile}>
      <LogoWrapper>
        <Logo />
        <Title>Your tool to compare app stores</Title>
      </LogoWrapper>
      <SearchBar/>
    </Wrapper>
  );
}
