import React from "react";
import Filters from "./components/common/Filters";
import SideBar from "./components/desktop/Sidebar";
import Chart from "./components/common/Chart";
import { Wrapper } from "./styles";
import useViewport from "hooks/viewport/useViewport";
import { ScreenSize } from "pages/CONTANTS";
import SidebarHeader from "./components/mobile/SidebarHeader";
import SidebarFooter from "./components/mobile/SidebarFooter";
import SubscribeBox from "./components/common/SubcribeBox";


function HomeView() {
  const { width } = useViewport();

  const displayHomepage = (): JSX.Element => {
    if (width <= ScreenSize.TABLET) {
      return displayMobile();
    } else {
      return displayDesktop();
    }
  };


  
  const displayMobile = (): JSX.Element => {
    return (
      <Wrapper>
        <Filters />
        <SidebarHeader />
        <Chart />
        <SubscribeBox />
        <SidebarFooter />
      </Wrapper>
    );
  };


  const displayDesktop = (): JSX.Element => {
    return (
      <Wrapper>
        <Filters/>
        <Chart />
        <SideBar />
        <SubscribeBox />
      </Wrapper>
    );
  };

  return displayHomepage();
}

export default HomeView;
