import { getTopAppsPercentageData, getTopAppsData } from "services";
import {
  FETCHING_APPS_DATA,
  FETCH_APPS_ERROR,
  TOP_APPS_PACKAGE_DATA,
  TOP_APPS_PACKAGE_PERCENTAGE_DATA,
} from "../CONSTANTS";

export const getTopAppsPercentage = () => {
  return (dispatch: any) => {
    dispatch({
      type: FETCHING_APPS_DATA,
    });

    getTopAppsPercentageData()
      .then((res: any) => {
        dispatch({
          type: TOP_APPS_PACKAGE_PERCENTAGE_DATA,
          payload: {
            percentages: res.percentages,
            totalApps: 20,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_APPS_ERROR,
          payload: error,
        });
      });
  };
};

export const getPopularAppsData = () => {
  return (dispatch: any) => {
    dispatch({
      type: FETCHING_APPS_DATA,
    });

    getTopAppsData()
      .then((res: any) => {
        dispatch({
          type: TOP_APPS_PACKAGE_DATA,
          payload: {
            packages: res.packages,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_APPS_ERROR,
          payload: error,
        });
      });
  };
};
