import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategoriesAppsPercentageAndData } from "redux/actions";
import { RootState } from "redux/types";
import CategoryFiltersAndCategories from "./components/CategoryFiltersAndCategories";
import Skeleton from "./components/Skeleton";

export function Header() {
  const isLoading = useSelector((state: RootState) => state.apps.isLoading);
  const activeCategoryId = useSelector(
    (state: RootState) => state.category.active
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategoriesAppsPercentageAndData(activeCategoryId));
  }, [activeCategoryId]);

  return <th>{isLoading ? <Skeleton /> : <CategoryFiltersAndCategories />}</th>;
}
