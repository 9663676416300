import { CategoryFilterType } from "helpers/enums";
import { CategoryData } from "helpers/interfaces";
import {
  GET_CATEGORY_FILTERS,
  SET_CATEGORY_FILTER_ACTIVE,
} from "redux/CONSTANTS";
import { categoryFilterReducerInitialState } from "redux/initialState";
import { setActiveCategory } from "./categoriesActions";

export const getCategoryFilters = () => {
  return {
    type: GET_CATEGORY_FILTERS,
    payload: {
      categoryFilters: categoryFilterReducerInitialState.categoryFilters,
      active: CategoryFilterType.APPS,
    },
  };
};

export const setActiveCategoryFilter = (id: CategoryFilterType) => {
  return (dispatch: any, getState: any) => {
    const proposedActiveCategory = getState().category.categories.find(
      (category: CategoryData) => category.type === id
    );

    const currentActivectiveCategoryId = getState().category.active;

    if (
      proposedActiveCategory &&
      currentActivectiveCategoryId !== proposedActiveCategory.id
    ) {
      dispatch(setActiveCategory(proposedActiveCategory.id));
    }

    dispatch({
      type: SET_CATEGORY_FILTER_ACTIVE,
      payload: id,
    });
  };
};
