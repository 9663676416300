import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Wrapper, Title } from "./styles";
import { CategoryFilterPropData } from "./interface";
import { RootState } from "redux/types";

export function CategoryFilter(props: CategoryFilterPropData) {
  const activeId = useSelector(
    (state: RootState) => state.categoryFilter.active
  );

  const isActive = () => {
    return activeId === props.categoryFilter.id;
  };

  return (
    <Wrapper
      active={isActive()}
      onClick={() => {
        props.updateState(props.categoryFilter.id);
      }}
    >
      <Title>{props.categoryFilter.title}</Title>
    </Wrapper>
  );
}

CategoryFilter.propTypes = {
  categoryFilter: PropTypes.object.isRequired,
  updateState: PropTypes.func.isRequired,
};
