import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import {
  categoryFilterReducerInitialState,
  categoryReducerInitialState,
  filterReducerInitialState,
  appsReducerInitialState,
  subscriptionReducerInitialState,
  moldalReducerInitialState,
  storesReducerInitialState,
  searchReducerInitialState,
  appDownloadsReducerInitialState,
} from "./initialState";
import { composeWithDevTools } from "redux-devtools-extension";



const initialState: any = {
  apps: appsReducerInitialState,
  filter: filterReducerInitialState,
  category: categoryReducerInitialState,
  categoryFilter: categoryFilterReducerInitialState,
  subscription: subscriptionReducerInitialState,
  modal: moldalReducerInitialState,
  stores: storesReducerInitialState,
  search: searchReducerInitialState,
  appDownloads: appDownloadsReducerInitialState,
};

const middleware = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(
  applyMiddleware(...middleware), 

));



export default store;
