import { SYSTEM_ERROR } from "../config/CONTANTS";
import barometerApi from "config/axios";
import { QueryData, QueryParam } from "./interface";

const buidQueryParams = (queryParams: QueryParam[]) => {
  const query: QueryData = {};

  queryParams.forEach((param: QueryParam) => {
    query[param.label] = param.value;
  });

  return query;
};

export const fetchData = (endpoint: string, queryParams: QueryParam[] = []) => {
  return new Promise((resolve, reject) => {
    try {
      const params = buidQueryParams(queryParams);

      barometerApi
        .get(endpoint, {
          params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          reject(err.response.data);
        });
    } catch (error) {
      console.error(error);
      reject(SYSTEM_ERROR);
    }
  });
};

export const postData = (
  endpoint: string,
  data: any = {},
  queryParams: QueryParam[] = []
) => {
  return new Promise((resolve, reject) => {
    try {
      const params = buidQueryParams(queryParams);

      barometerApi
        .post(endpoint, data, {
          params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.error(err);
          reject(err.response.data);
        });
    } catch (error) {
      console.error(error);
      reject(SYSTEM_ERROR);
    }
  });
};
