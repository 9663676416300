import React from "react";
import Labels from "./components/Labels";
import { Wrapper, Tag } from "./styles";

export function ChartKey() {
  return (
    <Wrapper>
      <Tag>Chart key</Tag>
      <Labels />
    </Wrapper>
  );
}
