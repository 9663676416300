import React from "react";
import PropTypes from "prop-types";
import { TogglePropData } from "./interface";
import { Wrapper, Input, Slider } from "./styles";

export function Toggle(props: TogglePropData) {
  return (
    <Wrapper>
      <Input
        type="checkbox"
        name="toggle"
        id="toggle"
        checked={props.checked}
        onChange={props.toggle}
      />
      <Slider htmlFor="toggle"></Slider>
    </Wrapper>
  );
}

Toggle.propTypes = {
  checked: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};
