import React from "react";
import { Wrapper, Percentage } from "./styles";

export function Percentages() {
  return (
    <Wrapper>
      <Percentage>0%</Percentage>
      <Percentage>20%</Percentage>
      <Percentage>40%</Percentage>
      <Percentage>60%</Percentage>
      <Percentage>80%</Percentage>
      <Percentage>100%</Percentage>
    </Wrapper>
  );
}
