import React from "react";
import PropTypes from "prop-types";
import Percentages from "./components/Percentages";
import { TFoot, Text, Wrapper } from "./styles";
import Toggle from "components/Toggle";
import { ChartFooterPropData } from "./interface";

export function Footer(props: ChartFooterPropData) {
  const toggleShowNumbers = () => {
    props.setShowNumbers(!props.showNumbers);
  };

  return (
    <TFoot>
      <tr>
        <td colSpan={2}>
          <Wrapper>
            <Text onClick={toggleShowNumbers}>Show numbers</Text>
            <Toggle checked={props.showNumbers} toggle={toggleShowNumbers} />
          </Wrapper>
        </td>
        <td>
          <Percentages />
        </td>
      </tr>
    </TFoot>
  );
}

Footer.protoTypes = {
  showNumbers: PropTypes.bool.isRequired,
  setShowNumbers: PropTypes.func.isRequired,
};
