import styled from "styled-components";
import { ScreenSize } from "pages/CONTANTS";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px 36px 24px;
  height: calc(100vh - 92px);
  max-height: calc(1024px - 92px);

  @media (max-width: ${ScreenSize.TABLET}px) {
    flex-direction: column;
    padding: 0;
    padding-botom: 81px;
    height: 100%;
    max-height: 100%;
  }
`;
