import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import Head from "./components/Head";
import Body from "./components/Body";
import Loader from "./components/Loader";
import { Wrapper, Container, TableData } from "./styles";
import { RootState } from "redux/types";
import { DetailsTablePropData } from "./interface";
import { ParamCategoryType, ParamFilterType } from "helpers/enums";
import {
  getAllAppsData,
  getCategoriesAppsData,
  getPopularAppsData,
} from "redux/actions";
import SearchBar from "components/SearchBar/SearchBar";
import { QueryParam } from "services/interface";
import { getAllAppsByName, getAllAppsDataReset } from "redux/actions/allAppsActions";
import { toCancelClean, toCleanSearch } from "redux/actions/searchActions";









export function Table(props: DetailsTablePropData) {
  const page = useSelector((state: RootState) => state.apps.page);
  const searchQuery = useSelector((state: RootState) => state.search.query);
  const append = useSelector((state: RootState) => state.search.append);
  const cleanSearch = useSelector((state: RootState) => state.search.toClean);
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchQuery != "") {
      dispatch(toCleanSearch());
      if (append)
        dispatch(getAllAppsByName(searchQuery, page, true));
      else
        dispatch(getAllAppsByName(searchQuery, page));
    }
    else {
      switch (props.filter) {
        case ParamFilterType.ALL_APPS:
          dispatch(getAllAppsData(page, !cleanSearch));
          break;

        case ParamFilterType.TOP_20:
          dispatch(getPopularAppsData());
          break;

        case ParamFilterType.CATEGORIES:
          if (props.category) {
            dispatch(getCategoriesAppsData(props.category, page, !cleanSearch));
          } else {
            dispatch(getCategoriesAppsData(ParamCategoryType.SOCIAL, page, !cleanSearch));
          }
          break;

        default:
          dispatch(getAllAppsData(page, !cleanSearch));
          break;
      }
      if (cleanSearch) {
        dispatch(toCancelClean()); 
      }
    }

  }, [props.filter, props.category, page, searchQuery]);


  return (
    <Wrapper>
      <Container>
        <TableData>
          <Head />
          <Body />
        </TableData>
      </Container>
      <Loader />
    </Wrapper>
  );
}

Table.propTypes = {
  filter: PropTypes.string.isRequired,
  category: PropTypes.string,
};
