import styled from "styled-components";
import { ScreenSize } from "pages/CONTANTS";

const Input = styled.input`
  padding: 9.5px 14px;
  color: rgba(0,0,0,.87);
  font-size: 0.875rem;
  border-radius: 8px;
  border: 1px solid #e9e9e9;
  background-color: #fff;

  %:focus {
    border: 1px solid #272846;
  }
`;

const SubmitButton = styled.button`
width: 120px;
border-radius: 16px;
color: white;
cursor: pointer;
font-family: Roboto;
font-size: 12px;
font-weight: 500;
line-height: 23px;
letter-spacing: 0em;
display: block;
height: 40px;
background: #272846;
border: none;
padding: 0;

`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  gap: 10px;
  @media (max-width: ${ScreenSize.TABLET}px) {
    align-items: center;
  }

`;

export const Info = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin: 0 12px;

  @media (max-width: ${ScreenSize.TABLET}px) {
    font-size: 14px;
    line-height: 24px;
    margin: 0;
    margin-bottom: 5px;
  }
`;

export const SubscribeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F18E4A;
  border-radius: 24px;
  padding: 4px, 16px, 4px, 22px;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: var(--white);
  text-decoration: none;
  width: 100%;
  max-width: 107px;
  border: none;
  min-height: 32px;
  cursor: pointer;

  @media (max-width: ${ScreenSize.TABLET}px) {
    max-width: 100%;
  }
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const Popup = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 736px;
  min-height: 312px;
`;

export const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 35px;
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  color: #6F6F6F;
`;

export const PopupTitle = styled.p`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;

  color: #171717;

`

export const PopupText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #171717;
  width: 80%;
  `


export const FormContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 10px;
  justify-content: end;
  width: 100%;
  gap: 0px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  gap: 10px;`

export const StyledSubmitButton = styled(SubmitButton)`
  position: absolute;
  width: 120px;
  height: 100%;
  margin-left: 1px;
`;

export const EmailInput = styled(Input).attrs(props => ({
  type: 'text',
  placeholder: 'Email',
}))`
  flex: 1;
  height: 40px;
  border-radius: 16px;
  

  &:focus {
    border: 1px solid #272846;
  }
`;
;
