import styled from "styled-components";
import { ScreenSize } from "pages/CONTANTS";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &::after {
    content: "";
    border: 0.5px solid white;
    width: 100%;
    opacity: 0.2;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 26px;

  @media (max-width: ${ScreenSize.TABLET}px) {
    flex-direction: row;
    align-items: center;
    margin-bottom: 22px;
  }
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0;

  @media (max-width: ${ScreenSize.TABLET}px) {
    margin-left: 9px;
  }
`;
