import { CHANGE_PAGE } from "redux/CONSTANTS";

export const changePage = () => {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: CHANGE_PAGE,
      payload: getState().apps.page + 1,
    });
  };
};
