export const GET_PACKAGE_PERCENTAGE: string = "/percentages";
export const GET_PACKAGES: string = "/packages";
export const GET_PERCENTAGE_TOP_APPS: string = "percentages/top_apps";
export const GET_TOP_APPS: string = "/top20";
export const GET_PACKAGE_PERCENTAGE_BY_CATEGORY: string =
  "/percentages/category";
export const GET_PACKAGES_BY_CATEGORY: string = "/categoryPackages";
export const POST_EMAIL: string = "/subscribe";
export const GET_STORES: string = "/stores";
export const SEARCH_PACKAGES: string = "/search";
export const GET_DOWNLOADS_DATA:string = "/app_downloads"
