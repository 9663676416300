import React from "react";
import PropTypes from "prop-types";
import { Background, Text } from "./styles";
import { LabelPropData } from "./interface";

export function Label(props: LabelPropData) {
  return (
    <Background color={props.color}>
      <Text>{props.text}</Text>
    </Background>
  );
}

Label.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};
