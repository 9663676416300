import React from "react";
import { Switch, Route } from "react-router-dom";
import { ROOT, ABOUT, POPULAR_APPS, PACKAGE_DETAILS } from "./CONTANTS";
import Home from "pages/Home";
import About from "pages/About";
import Details from "pages/Details";
import PackageDetails from "pages/PackageDetails";

export const RouterConfig = () => {
  return (
    <div>
      <Switch>
        <Route exact path={ROOT} component={Home} />
        <Route exact path={POPULAR_APPS} component={Details} />
        <Route exact path={ABOUT} component={About} />
        <Route exact path={PACKAGE_DETAILS} component={PackageDetails}/>
      </Switch>
    </div>
  );
};
