import React from "react";
import PropTypes from "prop-types";
import { Title } from "./styles";
import { ChartHeadPropData } from "../../interface";

export function SimpleHead(props: ChartHeadPropData) {
  return (
    <tr>
      <Title colSpan={2}>{props.activeFilter.title}</Title>
    </tr>
  );
}

SimpleHead.propTypes = {
  activeFilter: PropTypes.object.isRequired,
};
