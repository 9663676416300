import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCategories, setActiveCategory } from "redux/actions";
import Category from "./components/Category";
import { RootState } from "redux/types";
import { CategoryType } from "helpers/enums";
import { CategoryData } from "helpers/interfaces";
import { Wrapper } from "./styles";

export function Categories() {
  const categories = useSelector(
    (state: RootState) => state.category.categories
  );

  const activeCategoryFilterId = useSelector(
    (state: RootState) => state.categoryFilter.active
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (categories.length <= 0) {
      dispatch(getCategories());
    }
  }, []);

  const filterCategoriesByCategoryFilters = () => {
    return categories.filter(
      (category: CategoryData) => category.type === activeCategoryFilterId
    );
  };

  const updatCategoryState = (id: CategoryType) => {
    dispatch(setActiveCategory(id));
  };

  return (
    <Wrapper>
      {filterCategoriesByCategoryFilters().map(
        (category: CategoryData, index: number) => (
          <Category
            category={category}
            updateState={updatCategoryState}
            key={index}
          />
        )
      )}
    </Wrapper>
  );
}
