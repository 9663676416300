import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";
import { DownloadsChart } from "./Chart/DownloadsChart";
import { StoresChart } from "./Chart/StoresChart";

export function Data (total_downloads: boolean) {
  
    

    const downloads30d = [

        {
          "date": "NA",
          "Aptoide": "NA",
          "GooglePlay": "NA",
          "ApkPure": "NA",
          "Vivo": "NA",
          "Xiaomi": "NA",
          "NineApps": "NA",
          "UpToDown": "NA",
          "Gionee": "NA",
          "Multilaser": "NA",
          "CherryMobile": "NA",
          "Apptoko": "NA",
          "Meizu": "NA",
          "Cafebazaar": "NA",
          "Softonic": "NA",
          "Amazon": "NA",
          "TapTap": "NA",
          "Huawei": "NA",
          "Total" : "NA"

        },
        {
            "date": "NA",
            "Aptoide": "NA",
            "GooglePlay": "NA",
            "ApkPure": "NA",
            "Vivo": "NA",
            "Xiaomi": "NA",
            "NineApps": "NA",
            "UpToDown": "NA",
            "Gionee": "NA",
            "Multilaser": "NA",
            "CherryMobile": "NA",
            "Apptoko": "NA",
            "Meizu": "NA",
            "Cafebazaar": "NA",
            "Softonic": "NA",
            "Amazon": "NA",
            "TapTap": "NA",
            "Huawei": "NA",
            "Total" : "NA"
  
          },
          {
            "date": "NA",
            "Aptoide": "NA",
            "GooglePlay": "NA",
            "ApkPure": "NA",
            "Vivo": "NA",
            "Xiaomi": "NA",
            "NineApps": "NA",
            "UpToDown": "NA",
            "Gionee": "NA",
            "Multilaser": "NA",
            "CherryMobile": "NA",
            "Apptoko": "NA",
            "Meizu": "NA",
            "Cafebazaar": "NA",
            "Softonic": "NA",
            "Amazon": "NA",
            "TapTap": "NA",
            "Huawei": "NA",
            "Total" : "NA"
  
          },
          {
            "date": "NA",
            "Aptoide": "NA",
            "GooglePlay": "NA",
            "ApkPure": "NA",
            "Vivo": "NA",
            "Xiaomi": "NA",
            "NineApps": "NA",
            "UpToDown": "NA",
            "Gionee": "NA",
            "Multilaser": "NA",
            "CherryMobile": "NA",
            "Apptoko": "NA",
            "Meizu": "NA",
            "Cafebazaar": "NA",
            "Softonic": "NA",
            "Amazon": "NA",
            "TapTap": "NA",
            "Huawei": "NA",
            "Total" : "NA"
  
          },
          {
            "date": "NA",
            "Aptoide": "NA",
            "GooglePlay": "NA",
            "ApkPure": "NA",
            "Vivo": "NA",
            "Xiaomi": "NA",
            "NineApps": "NA",
            "UpToDown": "NA",
            "Gionee": "NA",
            "Multilaser": "NA",
            "CherryMobile": "NA",
            "Apptoko": "NA",
            "Meizu": "NA",
            "Cafebazaar": "NA",
            "Softonic": "NA",
            "Amazon": "NA",
            "TapTap": "NA",
            "Huawei": "NA",
            "Total" : "NA"
  
          },
          {
            "date": "NA",
            "Aptoide": "NA",
            "GooglePlay": "NA",
            "ApkPure": "NA",
            "Vivo": "NA",
            "Xiaomi": "NA",
            "NineApps": "NA",
            "UpToDown": "NA",
            "Gionee": "NA",
            "Multilaser": "NA",
            "CherryMobile": "NA",
            "Apptoko": "NA",
            "Meizu": "NA",
            "Cafebazaar": "NA",
            "Softonic": "NA",
            "Amazon": "NA",
            "TapTap": "NA",
            "Huawei": "NA",
            "Total" : "NA"
  
          },
          {
            "date": "NA",
            "Aptoide": "NA",
            "GooglePlay": "NA",
            "ApkPure": "NA",
            "Vivo": "NA",
            "Xiaomi": "NA",
            "NineApps": "NA",
            "UpToDown": "NA",
            "Gionee": "NA",
            "Multilaser": "NA",
            "CherryMobile": "NA",
            "Apptoko": "NA",
            "Meizu": "NA",
            "Cafebazaar": "NA",
            "Softonic": "NA",
            "Amazon": "NA",
            "TapTap": "NA",
            "Huawei": "NA",
            "Total" : "NA"
  
          },
          {
            "date": "NA",
            "Aptoide": "NA",
            "GooglePlay": "NA",
            "ApkPure": "NA",
            "Vivo": "NA",
            "Xiaomi": "NA",
            "NineApps": "NA",
            "UpToDown": "NA",
            "Gionee": "NA",
            "Multilaser": "NA",
            "CherryMobile": "NA",
            "Apptoko": "NA",
            "Meizu": "NA",
            "Cafebazaar": "NA",
            "Softonic": "NA",
            "Amazon": "NA",
            "TapTap": "NA",
            "Huawei": "NA",
            "Total" : "NA"
  
          },
          {
            "date": "NA",
            "Aptoide": "NA",
            "GooglePlay": "NA",
            "ApkPure": "NA",
            "Vivo": "NA",
            "Xiaomi": "NA",
            "NineApps": "NA",
            "UpToDown": "NA",
            "Gionee": "NA",
            "Multilaser": "NA",
            "CherryMobile": "NA",
            "Apptoko": "NA",
            "Meizu": "NA",
            "Cafebazaar": "NA",
            "Softonic": "NA",
            "Amazon": "NA",
            "TapTap": "NA",
            "Huawei": "NA",
            "Total" : "NA"
  
          },
          {
            "date": "NA",
            "Aptoide": "NA",
            "GooglePlay": "NA",
            "ApkPure": "NA",
            "Vivo": "NA",
            "Xiaomi": "NA",
            "NineApps": "NA",
            "UpToDown": "NA",
            "Gionee": "NA",
            "Multilaser": "NA",
            "CherryMobile": "NA",
            "Apptoko": "NA",
            "Meizu": "NA",
            "Cafebazaar": "NA",
            "Softonic": "NA",
            "Amazon": "NA",
            "TapTap": "NA",
            "Huawei": "NA",
            "Total" : "NA"
  
          },
          {
            "date": "NA",
            "Aptoide": "NA",
            "GooglePlay": "NA",
            "ApkPure": "NA",
            "Vivo": "NA",
            "Xiaomi": "NA",
            "NineApps": "NA",
            "UpToDown": "NA",
            "Gionee": "NA",
            "Multilaser": "NA",
            "CherryMobile": "NA",
            "Apptoko": "NA",
            "Meizu": "NA",
            "Cafebazaar": "NA",
            "Softonic": "NA",
            "Amazon": "NA",
            "TapTap": "NA",
            "Huawei": "NA",
            "Total" : "NA"
  
          },
          {
            "date": "NA",
            "Aptoide": "NA",
            "GooglePlay": "NA",
            "ApkPure": "NA",
            "Vivo": "NA",
            "Xiaomi": "NA",
            "NineApps": "NA",
            "UpToDown": "NA",
            "Gionee": "NA",
            "Multilaser": "NA",
            "CherryMobile": "NA",
            "Apptoko": "NA",
            "Meizu": "NA",
            "Cafebazaar": "NA",
            "Softonic": "NA",
            "Amazon": "NA",
            "TapTap": "NA",
            "Huawei": "NA",
            "Total" : "NA"
  
          },
          {
            "date": "NA",
            "Aptoide": "NA",
            "GooglePlay": "NA",
            "ApkPure": "NA",
            "Vivo": "NA",
            "Xiaomi": "NA",
            "NineApps": "NA",
            "UpToDown": "NA",
            "Gionee": "NA",
            "Multilaser": "NA",
            "CherryMobile": "NA",
            "Apptoko": "NA",
            "Meizu": "NA",
            "Cafebazaar": "NA",
            "Softonic": "NA",
            "Amazon": "NA",
            "TapTap": "NA",
            "Huawei": "NA",
            "Total" : "NA"
  
          },
          {
            "date": "NA",
            "Aptoide": "NA",
            "GooglePlay": "NA",
            "ApkPure": "NA",
            "Vivo": "NA",
            "Xiaomi": "NA",
            "NineApps": "NA",
            "UpToDown": "NA",
            "Gionee": "NA",
            "Multilaser": "NA",
            "CherryMobile": "NA",
            "Apptoko": "NA",
            "Meizu": "NA",
            "Cafebazaar": "NA",
            "Softonic": "NA",
            "Amazon": "NA",
            "TapTap": "NA",
            "Huawei": "NA",
            "Total" : "NA"
  
          },
          {
            "date": "NA",
            "Aptoide": "NA",
            "GooglePlay": "NA",
            "ApkPure": "NA",
            "Vivo": "NA",
            "Xiaomi": "NA",
            "NineApps": "NA",
            "UpToDown": "NA",
            "Gionee": "NA",
            "Multilaser": "NA",
            "CherryMobile": "NA",
            "Apptoko": "NA",
            "Meizu": "NA",
            "Cafebazaar": "NA",
            "Softonic": "NA",
            "Amazon": "NA",
            "TapTap": "NA",
            "Huawei": "NA",
            "Total" : "NA"
  
          },
          {
            "date": "NA",
            "Aptoide": "NA",
            "GooglePlay": "NA",
            "ApkPure": "NA",
            "Vivo": "NA",
            "Xiaomi": "NA",
            "NineApps": "NA",
            "UpToDown": "NA",
            "Gionee": "NA",
            "Multilaser": "NA",
            "CherryMobile": "NA",
            "Apptoko": "NA",
            "Meizu": "NA",
            "Cafebazaar": "NA",
            "Softonic": "NA",
            "Amazon": "NA",
            "TapTap": "NA",
            "Huawei": "NA",
            "Total" : "NA"
  
          },
          {
            "date": "NA",
            "Aptoide": "NA",
            "GooglePlay": "NA",
            "ApkPure": "NA",
            "Vivo": "NA",
            "Xiaomi": "NA",
            "NineApps": "NA",
            "UpToDown": "NA",
            "Gionee": "NA",
            "Multilaser": "NA",
            "CherryMobile": "NA",
            "Apptoko": "NA",
            "Meizu": "NA",
            "Cafebazaar": "NA",
            "Softonic": "NA",
            "Amazon": "NA",
            "TapTap": "NA",
            "Huawei": "NA",
            "Total" : "NA"
  
          },
          {
            "date": "NA",
            "Aptoide": "NA",
            "GooglePlay": "NA",
            "ApkPure": "NA",
            "Vivo": "NA",
            "Xiaomi": "NA",
            "NineApps": "NA",
            "UpToDown": "NA",
            "Gionee": "NA",
            "Multilaser": "NA",
            "CherryMobile": "NA",
            "Apptoko": "NA",
            "Meizu": "NA",
            "Cafebazaar": "NA",
            "Softonic": "NA",
            "Amazon": "NA",
            "TapTap": "NA",
            "Huawei": "NA",
            "Total" : "NA"
  
          },
          {
            "date": "NA",
            "Aptoide": "NA",
            "GooglePlay": "NA",
            "ApkPure": "NA",
            "Vivo": "NA",
            "Xiaomi": "NA",
            "NineApps": "NA",
            "UpToDown": "NA",
            "Gionee": "NA",
            "Multilaser": "NA",
            "CherryMobile": "NA",
            "Apptoko": "NA",
            "Meizu": "NA",
            "Cafebazaar": "NA",
            "Softonic": "NA",
            "Amazon": "NA",
            "TapTap": "NA",
            "Huawei": "NA",
            "Total" : "NA"
  
          },
          {
            "date": "NA",
            "Aptoide": "NA",
            "GooglePlay": "NA",
            "ApkPure": "NA",
            "Vivo": "NA",
            "Xiaomi": "NA",
            "NineApps": "NA",
            "UpToDown": "NA",
            "Gionee": "NA",
            "Multilaser": "NA",
            "CherryMobile": "NA",
            "Apptoko": "NA",
            "Meizu": "NA",
            "Cafebazaar": "NA",
            "Softonic": "NA",
            "Amazon": "NA",
            "TapTap": "NA",
            "Huawei": "NA",
            "Total" : "NA"
  
          },
          {
            "date": "NA",
            "Aptoide": "NA",
            "GooglePlay": "NA",
            "ApkPure": "NA",
            "Vivo": "NA",
            "Xiaomi": "NA",
            "NineApps": "NA",
            "UpToDown": "NA",
            "Gionee": "NA",
            "Multilaser": "NA",
            "CherryMobile": "NA",
            "Apptoko": "NA",
            "Meizu": "NA",
            "Cafebazaar": "NA",
            "Softonic": "NA",
            "Amazon": "NA",
            "TapTap": "NA",
            "Huawei": "NA",
            "Total" : "NA"
  
          },
          {
            "date": "NA",
            "Aptoide": "NA",
            "GooglePlay": "NA",
            "ApkPure": "NA",
            "Vivo": "NA",
            "Xiaomi": "NA",
            "NineApps": "NA",
            "UpToDown": "NA",
            "Gionee": "NA",
            "Multilaser": "NA",
            "CherryMobile": "NA",
            "Apptoko": "NA",
            "Meizu": "NA",
            "Cafebazaar": "NA",
            "Softonic": "NA",
            "Amazon": "NA",
            "TapTap": "NA",
            "Huawei": "NA",
            "Total" : "NA"
  
          },
          {
            "date": "NA",
            "Aptoide": "NA",
            "GooglePlay": "NA",
            "ApkPure": "NA",
            "Vivo": "NA",
            "Xiaomi": "NA",
            "NineApps": "NA",
            "UpToDown": "NA",
            "Gionee": "NA",
            "Multilaser": "NA",
            "CherryMobile": "NA",
            "Apptoko": "NA",
            "Meizu": "NA",
            "Cafebazaar": "NA",
            "Softonic": "NA",
            "Amazon": "NA",
            "TapTap": "NA",
            "Huawei": "NA",
            "Total" : "NA"
  
          },
          {
            "date": "NA",
            "Aptoide": "NA",
            "GooglePlay": "NA",
            "ApkPure": "NA",
            "Vivo": "NA",
            "Xiaomi": "NA",
            "NineApps": "NA",
            "UpToDown": "NA",
            "Gionee": "NA",
            "Multilaser": "NA",
            "CherryMobile": "NA",
            "Apptoko": "NA",
            "Meizu": "NA",
            "Cafebazaar": "NA",
            "Softonic": "NA",
            "Amazon": "NA",
            "TapTap": "NA",
            "Huawei": "NA",
            "Total" : "NA"
  
          },
          {
            "date": "NA",
            "Aptoide": "NA",
            "GooglePlay": "NA",
            "ApkPure": "NA",
            "Vivo": "NA",
            "Xiaomi": "NA",
            "NineApps": "NA",
            "UpToDown": "NA",
            "Gionee": "NA",
            "Multilaser": "NA",
            "CherryMobile": "NA",
            "Apptoko": "NA",
            "Meizu": "NA",
            "Cafebazaar": "NA",
            "Softonic": "NA",
            "Amazon": "NA",
            "TapTap": "NA",
            "Huawei": "NA",
            "Total" : "NA"
  
          },
          {
            "date": "NA",
            "Aptoide": "NA",
            "GooglePlay": "NA",
            "ApkPure": "NA",
            "Vivo": "NA",
            "Xiaomi": "NA",
            "NineApps": "NA",
            "UpToDown": "NA",
            "Gionee": "NA",
            "Multilaser": "NA",
            "CherryMobile": "NA",
            "Apptoko": "NA",
            "Meizu": "NA",
            "Cafebazaar": "NA",
            "Softonic": "NA",
            "Amazon": "NA",
            "TapTap": "NA",
            "Huawei": "NA",
            "Total" : "NA"
  
          },
          {
            "date": "NA",
            "Aptoide": "NA",
            "GooglePlay": "NA",
            "ApkPure": "NA",
            "Vivo": "NA",
            "Xiaomi": "NA",
            "NineApps": "NA",
            "UpToDown": "NA",
            "Gionee": "NA",
            "Multilaser": "NA",
            "CherryMobile": "NA",
            "Apptoko": "NA",
            "Meizu": "NA",
            "Cafebazaar": "NA",
            "Softonic": "NA",
            "Amazon": "NA",
            "TapTap": "NA",
            "Huawei": "NA",
            "Total" : "NA"
  
          },
          {
            "date": "NA",
            "Aptoide": "NA",
            "GooglePlay": "NA",
            "ApkPure": "NA",
            "Vivo": "NA",
            "Xiaomi": "NA",
            "NineApps": "NA",
            "UpToDown": "NA",
            "Gionee": "NA",
            "Multilaser": "NA",
            "CherryMobile": "NA",
            "Apptoko": "NA",
            "Meizu": "NA",
            "Cafebazaar": "NA",
            "Softonic": "NA",
            "Amazon": "NA",
            "TapTap": "NA",
            "Huawei": "NA",
            "Total" : "NA"
  
          },
          {
            "date": "NA",
            "Aptoide": "NA",
            "GooglePlay": "NA",
            "ApkPure": "NA",
            "Vivo": "NA",
            "Xiaomi": "NA",
            "NineApps": "NA",
            "UpToDown": "NA",
            "Gionee": "NA",
            "Multilaser": "NA",
            "CherryMobile": "NA",
            "Apptoko": "NA",
            "Meizu": "NA",
            "Cafebazaar": "NA",
            "Softonic": "NA",
            "Amazon": "NA",
            "TapTap": "NA",
            "Huawei": "NA",
            "Total" : "NA"
  
          },
          {
            "date": "NA",
            "Aptoide": "NA",
            "GooglePlay": "NA",
            "ApkPure": "NA",
            "Vivo": "NA",
            "Xiaomi": "NA",
            "NineApps": "NA",
            "UpToDown": "NA",
            "Gionee": "NA",
            "Multilaser": "NA",
            "CherryMobile": "NA",
            "Apptoko": "NA",
            "Meizu": "NA",
            "Cafebazaar": "NA",
            "Softonic": "NA",
            "Amazon": "NA",
            "TapTap": "NA",
            "Huawei": "NA",
            "Total" : "NA"
  
          }
      ]


  const totalDownloads = [
    {
      "name": "Aptoide",
      "value": "NA"
    },
    {
      "name": "GooglePLay",
      "value": "NA"
    },
    {
      "name": "Apptoko",
      "value": "NA"
    },
    {
      "name": "ApkPure",
      "value": "NA"
    },
    {
      "name": "Vivo",
      "value": "NA"
    },
    {
      "name": "Meizu",
      "value": "NA"
    }
  ];
      

    // data.map((i:any, j = "NA") => { 
    //     console.log(j)
    //     auxDate.setDate(date.getNAate() - j)
    //     i.date = aNAxDate.getDate() + " " + auxDate.getMonth();
    //     j++;
    // })




    if(total_downloads) {
      return totalDownloads;
    }
    else
      return downloads30d;

}

