import styled from "styled-components";
import { ScreenSize } from "pages/CONTANTS";

export const Wrapper = styled.div`
  flex: 0.3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 40px;
  padding-bottom: 48px;
  background-color: var(--blue);
  color: var(--white);
  border-radius: 18px;
  margin-right: 8px;
  height: 100%;

  @media (max-width: ${ScreenSize.TABLET}px) {
    margin-right: 0;
    margin-bottom: 8px;
    padding: 28px 24px 40px 24px;
    height: initial;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Title = styled.h1`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom 9px;

  @media (max-width: ${ScreenSize.TABLET}px) {
    margin-bottom: 20px;
  }
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 30px;

  @media (max-width: ${ScreenSize.TABLET}px) {
    margin-bottom: 25px;
  }
`;
