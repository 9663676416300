import React from "react";
import PropTypes from "prop-types";
import Image from "components/Image";
import { Wrapper, Name } from "./styles";
import { RowAppNamePropData } from "./interface";
import TextRedirect from "./components/TextRedirect";

export function AppName(props: RowAppNamePropData) {
  return (
    <Wrapper>
      <Image src={props.image} alt={props.name} width={14} height={14} />
      <TextRedirect name={props.name} packageName={props.packageName}/>
    </Wrapper>
  );
}

AppName.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  packageName: PropTypes.string.isRequired,
};
