import React, { useState } from "react";
import PropTypes from "prop-types";
import { TitlePropData } from "./interface";
import { ParamFilterType } from "helpers/enums";
import { Header } from "./styles";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";
import TotalApps from "pages/Home/components/common/Sidebar/Header/components/TotalApps";

export function Title(props: TitlePropData) {
  const searchRes = useSelector((state: RootState) => state.search.query);
  const totalPages = useSelector((state: RootState) => state.apps.totalPages);


  const getCurrentTitle = () => {
    if ( totalPages == 0 && searchRes !== "") {

      return "Results not found for: " + searchRes
    }

    if (searchRes !== "") {
      return "Search results for: " + searchRes
    }

    else {
      switch (props.filter) {
        case ParamFilterType.ALL_APPS:
          return "All apps and their versions";

        case ParamFilterType.CATEGORIES:
          if (props.category) {
            return `All apps and their versions by ${props.category} category`;
          } else {
            return "All apps and their versions by category";
          }

        case ParamFilterType.TOP_20:
          return "Top 20 apps and their versions";

        default:
          return "All apps and their versions";
      }
    }
  };

  return <Header>{getCurrentTitle()}</Header>;
}

Title.propTypes = {
  filter: PropTypes.string.isRequired,
  category: PropTypes.string,
};
