import {
  FETCHING_STORES_DATA,
  FETCH_STORES_ERROR,
  STORES_DATA,
} from "../CONSTANTS";
import { storesReducerInitialState } from "../initialState";

const storesReducer = (state = storesReducerInitialState, action: any) => {
  switch (action.type) {
    case FETCHING_STORES_DATA:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_STORES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case STORES_DATA:
      return {
        ...state,
        isLoading: false,
        stores: action.payload.stores,
      };

    default:
      return state;
  }
};

export default storesReducer;
