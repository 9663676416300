import styled from "styled-components";

export const Background = styled.div`
  width: 100%;
  max-width: 320px;
  height: 40px;
  border-radius: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.color === "orange" &&
    `background-color: var(--orange); color: var(--white);
    `}

  ${(props) =>
    props.color === "gray" &&
    `background-color: var(--lightGray); color: var(--white)
    `}

  ${(props) =>
    props.color === "lightgray" &&
    `background-color: var(--lightestGray); color: var(--negro)
    `}
`;

export const Text = styled.span`
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
`;
