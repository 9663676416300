import React from "react";
import Header from "../../common/Sidebar/Header";
import ChartKey from "../../common/Sidebar/ChartKey";
import { Wrapper } from "./styles";

export function SidebarHeader() {
  return (
    <Wrapper>
      <Header />
      <ChartKey />
    </Wrapper>
  );
}
