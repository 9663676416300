import { keyframes } from "styled-components";

export const fadeInAnimation = () => {
  return keyframes`
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    `;
};

export const slideRightAnimation = (percentage) => {
  return keyframes`
    0% {
      width: 0;
    }
    100% {
      width: ${percentage}%;
    }
  `;
};

export const growRightAnimation = (min, max) => {
  return keyframes`
    0% {
      -webkit-transform: scaleX(${min});
              transform: scaleX(${min});
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
    }
    100% {
      -webkit-transform: scaleX(${max});
              transform: scaleX(${max});
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
    }
  `;
};
