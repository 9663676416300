import { Store } from "helpers/Stores";
import { getStoresData } from "services";
import {
  STORES_DATA,
  FETCHING_STORES_DATA,
  FETCH_STORES_ERROR,
} from "../CONSTANTS";
import { StoreData, StoreObjectData } from "./interface";

export const getStores = () => {
  return (dispatch: any) => {
    dispatch({
      type: FETCHING_STORES_DATA,
    });

    getStoresData()
      .then((res: any) => {
        const stores = storeDataToStoreObject(res.stores);

        dispatch({
          type: STORES_DATA,
          payload: {
            stores,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_STORES_ERROR,
          payload: error.message,
        });
      });
  };
};

const storeDataToStoreObject = (stores: StoreData[]) => {
  const storesObj: StoreObjectData = {};

  stores.forEach((store: StoreData) => {
    storesObj[store.store] = new Store(store.name, store.icon, "");
  });

  return storesObj;
};
