import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";
import Row from "./components/Row";

export function Skeleton() {
  const stores = useSelector((state: RootState) => state.stores.stores);

  return (
    <tbody>
      {Object.keys(stores).map((_: string, index: number) => {
        return <Row key={index} />;
      })}
    </tbody>
  );
}
