import styled from "styled-components";

export const Link = styled.a`
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 10px;
line-height: 14px;
/* identical to box height, or 140% */
link    {color:#272846;}
visited {color:#272846;}
hover   {color:#272846;}
active  {color:#272846;}

text-decoration-line: underline;

color: #272846;

`;

export const StyledTd = styled.td`
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 10px;
line-height: 14px;
margin-top:0.5rem;

color: #272846;

`