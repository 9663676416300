import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: var(--blue);
  color: var(--white);
  border-radius: 18px;
  padding: 22px 45px 25px 24px;
  margin: 24px 22px 81px 24px;
  height: 100%;
  width: calc(100% - 46px);
`;
