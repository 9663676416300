import React from "react";
import PropTypes from "prop-types";
import { Container } from "./styles";
import { SkeletonTableData } from "./interface";

export function Data(props: SkeletonTableData) {
  return (
    <td>
      <Container format={props.format}></Container>
    </td>
  );
}

Data.propTypes = {
  format: PropTypes.string.isRequired,
};
