import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: var(--blue);
  color: var(--white);
  border-radius: 18px;
  padding: 52px 24px 32px 24px;
  margin: 16px 22px 32px 24px;
  height: 100%;
  width: calc(100% - 46px);
`;
