import React, { useState } from "react";
import Head from "./components/Head";
import Body from "./components/Body";
import Footer from "./components/Footer";
import { Wrapper, Table } from "./styles";

export function Chart() {
  const [showNumbers, setShowNumbers] = useState<boolean>(false);

  return (
    <Wrapper>
      <Table>
        <Head />
        <Body showNumbers={showNumbers} />
        <Footer setShowNumbers={setShowNumbers} showNumbers={showNumbers} />
      </Table>
    </Wrapper>
  );
}
