import styled, { css } from "styled-components";
import { growRightAnimation } from "helpers/animations";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: var(--blue);
  color: var(--white);
  border-radius: 18px;
  padding: 48px 24px 48px 24px;
  height: 100%;
  width: 200px;
`;

const animationForWrapper = css`
  display: initial;
  animation: ${growRightAnimation(0, 1)} 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
`;

export const AnimationWrapper = styled.div`
  display: none;
  position: absolute;
  top: 0;
  right: 32px;
  height: 96%;
  width: calc(100vw - 48px);
  background-color: var(--blue);
  border-radius: 18px;
  z-index: 1;

  ${(props) => props.animating && animationForWrapper}
`;
