
import { useState, FunctionComponent } from "react";
import React from "react";
import { SearchBarBox, TextBox, TextInput, SearchButton } from "./styles"
import { useDispatch, useSelector } from "react-redux";
import { setSearchQuery } from "redux/actions";
import { RootState } from "redux/types";
import { continueSearch } from "redux/actions/searchActions";
import { useHistory } from "react-router-dom";
import img from "./images/icons8-search-50.png"
import img2 from "./images/icons8-multiply-24.png"
import useViewport from "hooks/viewport/useViewport";
import { ScreenSize } from "pages/CONTANTS";




interface SearchBarProps {
  onSubmit?:  (query: string) => void;
  onChange?: (newValue: string) => void;
}


const SearchBar: FunctionComponent<SearchBarProps> = ({onSubmit, onChange}: SearchBarProps) => {
  const actualSearch = useSelector((state: RootState) => state.search.query);
  const [value, setValue] = useState<string>('');
  const dispatch = useDispatch();
  const history = useHistory();
  const [image,setImage] = useState(img);

  const { width } = useViewport();

  const mobile = width <=ScreenSize.TABLET ? true : false

  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setValue(value);
    
  };


  const onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if(event.keyCode === 13) {
      setImage(img2)

      if (actualSearch !== value) {
        dispatch(setSearchQuery(value))
      }
      else
        dispatch(continueSearch)
      history.push("/popular-apps/all");
    }
    
  };

  const click = () => {

    if(image == img) {
      setImage(img2)



      if (actualSearch !== value) {
        dispatch(setSearchQuery(value))
      }
      else
        dispatch(continueSearch)
      history.push("/popular-apps/all");
    }
    else {
      setImage(img);
      setValue("");
      dispatch(setSearchQuery(""));
      history.push("");
    }
  }

  if(history.location.pathname == "/") {
 
  return(
    <SearchBarBox mobile={mobile}>
      <TextBox mobile={mobile}>
        <TextInput type="text" placeholder={"Search App"} value={value} onChange={onValueChange} onKeyUp={onKeyUp}/>
        {value !== ''}
        <SearchButton onClick={ click }>
          <img src={img} alt="Search"/>
        </SearchButton>
      </TextBox>
    </SearchBarBox>
  )
  }
  else {

    return(
      <SearchBarBox mobile={mobile}>
        <TextBox mobile={mobile}>
          <TextInput type="text" placeholder={"Search App"} value={value} onChange={onValueChange} onKeyUp={onKeyUp}/>
          {value !== ''}
          <SearchButton onClick={ click }>
            <img src={image} alt="Search"/>
          </SearchButton>
        </TextBox>
      </SearchBarBox>
    )
  }
}

export default SearchBar;
