import React from "react";
import PropTypes from "prop-types";
import Table from "./components/Table";
import Title from "./components/Title";
import { Wrapper } from "./styles";
import { DetailsViewPropData } from "./interface";
import SearchBar from "components/SearchBar/SearchBar";
import { ParamFilterType } from "helpers/enums";



function DetailsView(props: DetailsViewPropData) {
  return (
    <Wrapper>
      <Title filter={props.filter} category={props.category} />
      <Table filter={props.filter} category={props.category} />
    </Wrapper>
  );
}

DetailsView.propTypes = {
  filter: PropTypes.string.isRequired,
  category: PropTypes.string,
};

export default DetailsView;
