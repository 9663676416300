import styled from "styled-components";
import { ScreenSize } from "pages/CONTANTS";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;

  @media (max-width: ${ScreenSize.TABLET}px) {
    img {
      transform: scale(1.5);
      transform-origin: left top;
    }
  }
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 7px;
  line-height: 20px;
  margin-right: 17px;

  @media (max-width: ${ScreenSize.TABLET}px) {
    font-size: 9px;
    line-height: 14px;
    margin-right: 10px;
  }
`;
