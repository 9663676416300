import styled from "styled-components";

export const Index = styled.td`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: var(--mediumGray);
  padding-right: 14px !important;
`;

export const LogoCell = styled.td`
  padding-right: 16px !important;
`;
