import styled from "styled-components";
import { ScreenSize } from "pages/CONTANTS";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width:auto;
  margin: 0 16px 24px 16px;
  padding: 24px 32px;
  background-color: var(--white);
  border-radius: 18px;
  color: var(--white);

  @media (max-width: ${ScreenSize.TABLET}px) {
    border-radius: 0;
    margin: 0;
    padding: 28px 24px;
  }
`

export const ExpandButton = styled.button`
width: 164px;
height: 32px;
background: #E1E1E3;
border-radius: 48px;
border:none;
font-family: 'Roboto';
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 23px;
/* identical to box height, or 192% */
display:inline-block;
cursor:pointer;
:hover {
  background-color: #cacace;
}

text-align: center;

/* Barometer */

color: #272846;

`;
