import styled from "styled-components";

export const CostumizedToolTip = styled.div`
position: relative;
width: fit-content;
min-width: 112px;
height: fit-content;
display:flex;
align-items: center;
flex-direction: column;

/* White */
box-shadow: 0px 0px 19px rgba(0, 0, 0, .2);
border-radius: 8px;

background: #FFFFFF;
/* Default - Bold */

`

export const Date = styled.h1`
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 14px;
/* or 140% */


/* Grey medium */

color: #B3B3B3;
`

export const DateBox = styled.div`
display:flex;
width: 100%;
height:24px;
align-items: center;
flex-direction: column;
justify-content:center;

`
export const DateLine = styled.div`
position: relative;
width: 100%;
height: 0px;

/* Grey light */

border: 1px solid #EBEBEB;
margin-bottom: 7px;`


export const StoreName = styled.h1`
/* Small Text/Medium - XS */
margin-top: 2px;
margin-bottom: 5px;
margin-left:10px;
margin-right:10px;
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 16px;
/* identical to box height, or 133% */

text-align: center;

color: ${props => props.color};

;`

export const LegendDiv = styled.div`
position:relative;
width:80%;
height:fit-content;
margin-top: 15px;
margin-right: 20px;
margin-left: 20px;
display:flex;
flex-wrap:wrap;
align-items: center;
justify-content: center;

`
export const LegendStores = styled.span`
margin-left:5px;
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 16px;
/* identical to box height, or 133% */
text-decoration-line: ${props => props.hide == true ? "line-through" : ""} ;


`
export const StoresSpan = styled.span`
display:flex;
flex-direction:row;
margin-right: 30px;
margin-bottom: 20px;
color: ${props => props.color} ;
cursor: pointer;`