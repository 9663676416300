import { SuccessMessage } from "./styles";
import Image from "components/Image";
import SuccessIcon from "assets/images/success-icon.svg";



const Success = ({ success }: { success?: string | null }) => {
  if (success) {
    return (
      <SuccessMessage>
        <Image src={SuccessIcon} alt="success"/>{success}
      </SuccessMessage>
    );
  }

  return <></>;
};

export default Success;
