import React, { useEffect } from "react";
import HomeView from "./HomeView";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/types";
import { getStores, setSearchQuery } from "redux/actions";
import SearchBar from "components/SearchBar/SearchBar";

export function HomeContainer() {
  const error = useSelector((state: RootState) => state.apps.error);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStores());
    dispatch(setSearchQuery(""))
  }, []);


  return <div>{error ? <div>Error</div> : <div><HomeView /></div>}</div>;
}
