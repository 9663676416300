import styled from "styled-components";
import { Link } from "react-router-dom";
import { ScreenSize } from "pages/CONTANTS";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Info = styled.p`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  margin-bottom: 24px;

  @media (max-width: ${ScreenSize.TABLET}px) {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 26px;
  }
`;

export const AboutLink = styled(Link)`
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  color: var(--white);

  :hover {
    color: var(--lighterNegro);
  }

  @media (max-width: ${ScreenSize.TABLET}px) {
    font-size: 14px;
    line-height: 24px;
  }
`;
