import React from "react";
import Image from "components/Image";
import LogoIcon from "assets/images/logo_icon.svg";
import { Wrapper } from "./styles";

export function Logo() {
  return (
    <Wrapper to="/">
      <Image src={LogoIcon} alt="Logo" />
    </Wrapper>
  );
}
