import { CLEAN_PLOTS, FETCHING_APPS_DATA, FETCH_APPS_ERROR, GET_DOWNLOADS30_DATA, GET_TOP_GAINER_LOSER, GET_TOTAL_DOWNLOADS_DATA } from "redux/CONSTANTS";
import { getDownloadsData } from "services/calls";
import { QueryParam } from "services/interface";

export const getAppDownloads = (name: string, totalDownloads:string = "downloads_30d") => {
    const query: QueryParam[] = [
      new QueryParam("query", name),
      new QueryParam("total_downloads", totalDownloads),
    ];
    
    return (dispatch: any) => {
      dispatch({
        type: FETCHING_APPS_DATA,
      })
      getDownloadsData(query)
        .then((res: any) => {

            if(totalDownloads == "downloads_30d") {

                dispatch({
                type: GET_DOWNLOADS30_DATA,
                payload: {
                    downloads30d:res.reverse()
                },
                });
            }
            else if(totalDownloads == "downloads_by_store"){
                dispatch({
                    type: GET_TOTAL_DOWNLOADS_DATA,
                    payload: {
                        totalDownloads:res
                    },
                    });
            }
            else {
              dispatch({
                type: GET_TOP_GAINER_LOSER,
                payload: {
                    topGainerLoser:res
                },
                });

            }
          }
          
        
        )
        .catch((error) => {
          dispatch({
            type: FETCH_APPS_ERROR,
            payload: error,
          });
        });
    }
  };

  export const cleanPlots = () => {
    return {
        type:CLEAN_PLOTS
    }
}