import styled from "styled-components";

export const Title = styled.th`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: var(--blue);
`;

export const Subtitle = styled.th`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  color: var(--lightNegro);
`;
