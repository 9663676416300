import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 8px;
  background-color: var(--lightestGray);
`;

export const Unit1 = styled.div`
  height: 100%;
  background-color: var(--orange);
  width: ${(props) => props.unit}%;
  position: relative;

  &::after {
    content: "10000";
    position: absolute;
    font-size: 12px;
    right: 0;
  }
`;

export const Unit2 = styled.div`
  height: 100%;
  background-color: var(--lightGray);
  width: ${(props) => props.unit}%;
`;
