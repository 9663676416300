import styled from "styled-components";
import { ScreenSize } from "pages/CONTANTS";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.mobile == true ? "column" : "row"}; 
  align-items: baseline;
  justify-content: space-between;
  padding: 0 16px 8px 16px;
  width:100%;
  margin-bottom: ${props => props.mobile == true ? "20px" : ""}; 
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;

  @media (max-width: ${ScreenSize.TABLET}px) {
    flex-direction: column;
  }
`;

export const Title = styled.p`
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: var(--blue);
  margin-left: 32px;

  @media (max-width: ${ScreenSize.TABLET}px) {
    margin-left: 0;
    margin-top: 22px;
    margin-bottom: 26px;
  }
`;
