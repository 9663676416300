import styled from "styled-components";

export const Tag = styled.span`
  display: none;
  position: relative;
  background-color: var(--blue);
  padding: 3px 6px 3px 6px;
  color: var(--white);
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  width: fit-content;
  border-radius: 2px;
  white-space: nowrap;

  &::after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--blue) transparent transparent transparent;
  }
`;
