import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Wrapper, Title } from "./styles";
import { CategoryPropData } from "./interface";
import { RootState } from "redux/types";

export function Category(props: CategoryPropData) {
  const activeId = useSelector((state: RootState) => state.category.active);

  const isActive = () => {
    return activeId === props.category.id;
  };

  return (
    <Wrapper
      active={isActive()}
      onClick={() => {
        props.updateState(props.category.id);
      }}
    >
      <Title>{props.category.title}</Title>
    </Wrapper>
  );
}

Category.propTypes = {
  category: PropTypes.object.isRequired,
  updateState: PropTypes.func.isRequired,
};
