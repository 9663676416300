import React from "react";
import PropTypes from "prop-types";
import { Wrapper, Text, TextWrapper } from "./styles";
import TotalApps from "./components/TotalApps";
import TextRedirect from "./components/TextRedirect";
import { SidebarHeaderPropData } from "./interface";

export function Header(props: SidebarHeaderPropData) {
  return (
    <Wrapper>
      <TotalApps />
      <TextWrapper>
        <TextRedirect setAnimating={props.setAnimating} />
        <Text>in the last 30 days</Text>
      </TextWrapper>
    </Wrapper>
  );
}

Header.propTypes = {
  setAnimating: PropTypes.func,
};
