import styled from "styled-components";
import { ScreenSize } from "pages/CONTANTS";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 36px 16px;
  height: calc(100vh - 92px);
  max-height: calc(1024px - 92px);

  @media (max-width: ${ScreenSize.TABLET}px) {
    flex-direction: column;
    padding: 0 22px 73px 24px;
  }
`;
