import { GET_FILTERS, SET_FILTER_ACTIVE } from "redux/CONSTANTS";
import { filterReducerInitialState } from "redux/initialState";

const filterReducer = (state = filterReducerInitialState, action: any) => {
  switch (action.type) {
    case GET_FILTERS:
      return {
        ...state,
        filters: action.payload.filters,
        active: action.payload.active,
      };

    case SET_FILTER_ACTIVE:
      return {
        ...state,
        active: action.payload,
      };

    default:
      return state;
  }
};

export default filterReducer;
