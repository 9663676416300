export const FETCHING_APPS_DATA: string = "FETCHING_APPS_DATA";

export const FETCH_APPS_ERROR: string = "FETCH_APPS_ERROR";

export const ALL_APPS_PACKAGE_AND_PERCENTAGE_DATA: string =
  "ALL_APPS_PACKAGE_AND_PERCENTAGE_DATA";

export const TOP_APPS_PACKAGE_PERCENTAGE_DATA: string =
  "TOP_APPS_PACKAGE_PERCENTAGE_DATA";

export const CATEGORIES_APPS_PACKAGE_AND_PERCENTAGE_DATA: string =
  "CATEGORIES_APPS_PACKAGE_AND_PERCENTAGE_DATA";

export const ALL_APPS_PACKAGE_DATA: string = "ALL_APPS_PACKAGE_DATA";

export const ALL_APPS_PACKAGE_DATA_APPEND :string = "ALL_APPS_PACKAGE_DATA_APPEND";

export const TOP_APPS_PACKAGE_DATA: string = "TOP_APPS_PACKAGE_DATA";

export const CATEGORIES_APPS_PACKAGE_DATA: string =
  "CATEGORIES_APPS_PACKAGE_DATA";

export const GET_FILTERS: string = "GET_FILTERS";

export const SET_FILTER_ACTIVE: string = "SET_FILTER_ACTIVE";

export const GET_CATEGORIES: string = "GET_CATEGORIES";

export const SET_CATEGORY_ACTIVE: string = "SET_CATEGORY_ACTIVE";

export const GET_CATEGORY_FILTERS: string = "GET_CATEGORY_FILTERS";

export const SET_CATEGORY_FILTER_ACTIVE: string = "SET_CATEGORY_FILTER_ACTIVE";

export const CHANGE_PAGE: string = "CHANGE_PAGE";

export const RESET_PACKAGES_DATA_REQUEST: string =
  "RESET_PACKAGES_DATA_REQUEST";

export const POSTING_SUBSCRIPTION_DATA: string = "POSTING_SUBSCRIPTION_DATA";

export const POST_SUBSCRIPTION_ERROR: string = "POST_SUBSCRIPTION_ERROR";

export const EMAIL_SUBSCRIPTION: string = "EMAIL_SUBSCRIPTION";

export const RESET_SUBSCRIPTION: string = "RESET_SUBSCRIPTION";

export const SHOW_MODAL: string = "SHOW_MODAL";

export const HIDE_MODAL: string = "HIDE_MODAL";

export const FETCHING_STORES_DATA: string = "FETCHING_STORES_DATA";

export const FETCH_STORES_ERROR: string = "FETCH_STORES_ERROR";

export const STORES_DATA: string = "STORES_DATA";

export const GET_APP_DATA_BY_SEARCH: string = "GET_APP_DATA_BY_SEARCH";

export const GET_APP_DATA_BY_SEARCH_APPEND: string = "GET_APP_DATA_BY_SEARCH_APPEND";

export const SET_QUERY: string = "SET_QUERY";

export const CONTINUE_QUERY:string = "CONTINUE_QUERY";

export const CLEAN_SEARCH:string = "CLEAN_SEARCH";

export const CANCEL_CLEAN:string = "CANCEL_CLEAN";

export const ALL_APPS_PACKAGE_DATA_RESET:string = "ALL_APPS_PACKAGE_DATA_RESET";

export const GET_DOWNLOADS30_DATA:string = "GET_DOWNLOADS30_DATA";

export const GET_TOTAL_DOWNLOADS_DATA:string = "GET_TOTAL_DOWNLOADS_DATA";

export const GET_TOP_GAINER_LOSER:string = "GET_TOP_GAINER_LOSER";

export const CLEAN_PLOTS:string = "CLEAN_PLOTS";

export const CATEGORIES_APPS_PACKAGE_DATA_APPEND:string = "CATEGORIES_APPS_PACKAGE_DATA_APPEND";
