import { ErrorMessage } from "./styles";  
import Image from "components/Image";
import ErrorIcon from "assets/images/error-icon.svg";

const Error = ({error}: {error?: string | null}) => {
  if(error) {
   return  <ErrorMessage> <Image src={ErrorIcon} alt="error"/> {error}</ErrorMessage>
  }

  return <></>;
}

export default Error;

