import styled from "styled-components";




export const SearchBarBox = styled.div`
position: relative;
width: ${props => props.mobile == true ? "100%" : "328px"};
height: 40px;
margin-right: ${props => props.mobile == true ? "" : "16px"}; 


`;

export const TextBox = styled.div`
display:flex;
align-items:center;
padding:0 0.5rem;
position: relative;
width: ${props => props.mobile == true ? "100%" : "328px"};
height: 40px;
background: #FFFFFF;
border-radius: 16px;


`;

export const TextInput = styled.input`
position: relative;
width: 260px;
margin-left:1rem;
  font-size: 14px;
  border: none;
  outline: none; 
  color: #000000;
  background: #FFFFFF;
border-radius: 16px;
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999999;
  opacity: 1; /* Firefox */
}
`;

export const SearchButton = styled.button`
position: relative;
margin-left:auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  background: none;
  outline: none;

  img {
    width: 17.5px;
    height: 17.5px;
  }
`;

