import React from "react";
import PropTypes from "prop-types";
import Rows from "./components/Rows";
import { ChartBodyViewPropData } from "./interface";

function BodyView(props: ChartBodyViewPropData) {
  return (
    <tbody>
      <Rows stores={props.stores} showNumbers={props.showNumbers} />
    </tbody>
  );
}

BodyView.propTypes = {
  stores: PropTypes.array.isRequired,
  showNumbers: PropTypes.bool.isRequired,
};

export default BodyView;
