import { GET_CATEGORIES, SET_CATEGORY_ACTIVE } from "redux/CONSTANTS";
import { categoryReducerInitialState } from "redux/initialState";

const categoryReducer = (state = categoryReducerInitialState, action: any) => {
  switch (action.type) {
    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload.categories,
        active: action.payload.active,
      };

    case SET_CATEGORY_ACTIVE:
      return {
        ...state,
        active: action.payload,
      };

    default:
      return state;
  }
};

export default categoryReducer;
