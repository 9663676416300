import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { TextLink } from "./styles";
import { CategoryType, FilterType } from "helpers/enums";
import { RootState } from "redux/types";
import { useHistory } from "react-router-dom";
import { TextRedirectPropData } from "./interface";

export function TextRedirect(props: TextRedirectPropData) {
  const activeFilterId = useSelector((state: RootState) => state.filter.active);
  const activeCategoryId = useSelector(
    (state: RootState) => state.category.active
  );

  const history = useHistory();

  const getDetailsRoute = () => {
    let endpoint = "/popular-apps";

    switch (activeFilterId) {
      case FilterType.ALL_APPS:
        endpoint += "/all";
        break;
      case FilterType.CATEGORIES:
        endpoint += "/categories";
        break;
      case FilterType.TOP_APPS:
        endpoint += "/top_20";
        break;
      default:
        endpoint += "/all";
        break;
    }

    if (activeFilterId === FilterType.CATEGORIES) {
      switch (activeCategoryId) {
        case CategoryType.COMMUNICATION:
          endpoint += "/COMMUNICATION";
          break;
        case CategoryType.SOCIAL:
          endpoint += "/SOCIAL";
          break;
        case CategoryType.TOOLS:
          endpoint += "/TOOLS";
          break;
        case CategoryType.VIDEO_PLAYERS:
          endpoint += "/VIDEO_PLAYERS";
          break;
        case CategoryType.GAME_ACTION:
          endpoint += "/GAME_ACTION";
          break;
        case CategoryType.GAME_ADVENTURE:
          endpoint += "/GAME_ADVENTURE";
          break;
        case CategoryType.GAME_SPORTS:
          endpoint += "/GAME_SPORTS";
          break;
        case CategoryType.GAME_STRATEGY:
          endpoint += "/GAME_STRATEGY";
          break;
        default:
          endpoint += "/COMMUNICATION";
          break;
      }
    }

    return endpoint;
  };

  const animateGoToDetailsRoute = () => {
    if (!props.setAnimating) {
      history.push(getDetailsRoute());
      return;
    }

    props.setAnimating?.(true);

    setTimeout(() => {
      history.push(getDetailsRoute());
    }, 1000);
  };

  return (
    <TextLink onClick={animateGoToDetailsRoute}>Total listed apps</TextLink>
  );
}

TextRedirect.propTypes = {
  setAnimating: PropTypes.func,
};
