
import {
    CANCEL_CLEAN,
    CLEAN_SEARCH,
    CONTINUE_QUERY,
    SET_QUERY
} from "../CONSTANTS";

export const setSearchQuery = (query:string) => {
    return {
        type:SET_QUERY,
        payload:query
    }
}

export const continueSearch = () => {
    return {
        type:CONTINUE_QUERY
    }
}

export const toCleanSearch = () => {
    return {
        type:CLEAN_SEARCH
    }
}

export const toCancelClean = () => {
    return {
        type:CANCEL_CLEAN
    }
}