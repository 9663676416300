import { CategoryFilterType, CategoryType, FilterType } from "helpers/enums";
import { Data } from "pages/PackageDetails/components/Charts/Data";

export const appsReducerInitialState = {
  isLoading: false,
  percentages: [],
  packages: [],
  totalPages: 0,
  page: 1,
  totalApps: 0,
  error: null,
};

export const storesReducerInitialState = {
  isLoading: false,
  stores: [],
  error: null,
};

export const subscriptionReducerInitialState = {
  isLoading: false,
  subscribed: false,
  error: null,
};

export const moldalReducerInitialState = {
  show: false,
};

export const filterReducerInitialState = {
  filters: [
    {
      id: FilterType.ALL_APPS,
      title: "All apps",
    },
    {
      id: FilterType.CATEGORIES,
      title: "Categories",
    },
    {
      id: FilterType.TOP_APPS,
      title: "Popular Apps",
    },
  ],
  active: FilterType.ALL_APPS,
};

export const categoryReducerInitialState = {
  categories: [
    {
      id: CategoryType.SOCIAL,
      type: CategoryFilterType.APPS,
      title: "Social",
    },
    {
      id: CategoryType.COMMUNICATION,
      type: CategoryFilterType.APPS,
      title: "Communication",
    },
    {
      id: CategoryType.TOOLS,
      type: CategoryFilterType.APPS,
      title: "Tools",
    },
    {
      id: CategoryType.VIDEO_PLAYERS,
      type: CategoryFilterType.APPS,
      title: "Video Players and Editors",
    },
    {
      id: CategoryType.GAME_ACTION,
      type: CategoryFilterType.GAMES,
      title: "Action",
    },
    {
      id: CategoryType.GAME_ADVENTURE,
      type: CategoryFilterType.GAMES,
      title: "Adventure",
    },
    {
      id: CategoryType.GAME_STRATEGY,
      type: CategoryFilterType.GAMES,
      title: "Strategy",
    },
    {
      id: CategoryType.GAME_SPORTS,
      type: CategoryFilterType.GAMES,
      title: "Sports",
    },
  ],
  active: CategoryType.SOCIAL,
};

export const categoryFilterReducerInitialState = {
  categoryFilters: [
    {
      id: CategoryFilterType.APPS,
      title: "Apps",
      active: true,
    },
    {
      id: CategoryFilterType.GAMES,
      title: "Games",
      active: false,
    },
  ],
  active: CategoryFilterType.APPS,
};


export const searchReducerInitialState = {
  query: "",
  append: false,
  toClean: false,
}

export const appDownloadsReducerInitialState = {
  downloads30d: {
    downloads30d: Data(false)
  },
  totalDownloads: {
    totalDownloads: Data(true)
  },
  topGainerLoser: {
    topGainerLoser: {
      gainer: "",
      loser: ""
    }
  }
}
