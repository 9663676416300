import React from "react";
import PropTypes from "prop-types";
import { SpinnerPropData } from "./interface";
import { Loader } from "./styles";

export function Spinner(props: SpinnerPropData) {
  return (
    <Loader format={props.format}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Loader>
  );
}

Spinner.propTypes = {
  format: PropTypes.string,
};
