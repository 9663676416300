import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { getColor } from "../Charts";
import { TotalDownloadsData } from "../interface";
import { TotalDownloadsCharsProp } from "./interface";


      
                                


export function StoresChart(props: TotalDownloadsCharsProp) {

  const data = props.data.filter((i:any)=> typeof(i.value) == "number" && Math.round((i.value/props.totalDownloads)*100) > 0);
  const filteredData = data.filter((entry:any) => (entry.value / props.totalDownloads) < 0.02);
  const otherData = filteredData.reduce((acc: any, curr:any) => acc + curr.value, 0);
  const newData = data.filter((entry:any) => (entry.value / props.totalDownloads) >= 0.02);
  if (otherData > 0) {
    newData.push({name: 'others', value: otherData});
  }
  
    return (
      <ResponsiveContainer height='100%' width='100%'>
        <PieChart width={730} height={300}>
        <Pie data={newData} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={45} outerRadius={80} fill="#82ca9d" label={({
          cx,
          cy,
          midAngle,
          innerRadius,
          outerRadius,
          value,
          index
        }) => {
          const RADIAN = Math.PI / 180;
          // eslint-disable-next-line
          const radius = 25 + innerRadius + (outerRadius - innerRadius);
          // eslint-disable-next-line
          const x = cx + radius * Math.cos(-midAngle * RADIAN);
          // eslint-disable-next-line
          const y = cy + radius * Math.sin(-midAngle * RADIAN);

          return (
            <g>
              <text
                fontSize={12}
                x={x}
                y={y}
                fill={"#B3B3B3"}
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
              >
                {newData[index].name} 
            </text>
            <text
                fontSize={12}
                x={x}
                y={y}
                fill={"#272846"}
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
                dy={18}
              >
              {Math.round((value/props.totalDownloads)*100)} %
            </text>
            </g>
          );
        }}>
        {Object.values(newData).map((entry:any, index) => (
              <Cell key={`cell-${index}`} fill={getColor(entry.name)} />
            ))}
        </Pie>
      </PieChart>
      </ResponsiveContainer>
    
    );
  }

  
  