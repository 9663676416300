import styled from "styled-components";
import { Link } from "react-router-dom";

export const Wrapper = styled(Link)`
  padding: 24px 24px 25px 24px;
  background-color: var(--blue);
  border-radius: 0 0 18px 18px;

  &:hover {
    cursor: pointer;
  }
`;
