import Subscribe from "./components";
import { Wrapper } from "./styles";

export function SubscribeBox() {
  return (
    <Wrapper>
      <Subscribe />
    </Wrapper>
  );
}
