import styled from "styled-components";
import { slideRightAnimation } from "helpers/animations";

export const UnitBar = styled.div`
  height: 100%;
  width: 0;
  position: relative;
  -webkit-animation: ${(props) => slideRightAnimation(props.percentage)} 1s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: ${(props) => slideRightAnimation(props.percentage)} 1s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  ${(props) =>
    props.color === "orange" &&
    `
            background-color: var(--orange);
          `}

  ${(props) =>
    props.color === "lightGray" &&
    `
          background-color: var(--lightGray);
        `}

    &:hover {
    cursor: pointer;

    .tooltip {
      display: block;
    }
  }
`;

export const Number = styled.div`
  display: none;

  ${(props) =>
    props.showNumbers &&
    props.percentage > 0 &&
    `       
            display: block;
            position: absolute;
            white-space: nowrap;
            font-weight: 700;
            font-size: 10px;
            line-height: 14px;
            width: fit-content;
            right: 1px;
            top: 8px;

            ${
              props.width > props.unitBarWidth &&
              props.color === "lightGray" &&
              `
                  left: 1px;
                
              `
            };
        
            ${
              props.color === "orange" &&
              `
                        color: var(--orange);
                      `
            };
        
            ${
              props.color === "lightGray" &&
              `
                      color: var(--lightGray);
                    `
            };
          `}
`;

export const Tag = styled.div`
  position: absolute;
  bottom: 12px;
  transform: translateX(-50%);
  left: 50%;
`;
