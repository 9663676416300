import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const Input = styled.input`
  width: 0;
  height: 0;
  visibility: hidden;

  &:checked + label::after {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
  }

  &:checked + label {
    background-color: var(--blue);
  }
`;

export const Slider = styled.label`
  display: block;
  background-color: var(--lightestGray);
  width: 28px;
  height: 16px;
  border-radius: 100px;
  position: relative;
  cursor: pointer;
  transition: 0.5s;

  &::after {
    content: "";
    width: 12px;
    height: 12px;
    background-color: var(--white);
    position: absolute;
    border-radius: 50%;
    top: 2px;
    left: 2px;
    transition: 0.3s;
  }
`;
