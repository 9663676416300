import React from "react";
import PropTypes from "prop-types";
import { TableRowPropData } from "./interface";
import AppName from "./components/AppName";
import AppVersions from "./components/AppVersions";

export function Row(props: TableRowPropData) {
  return (
    <tr>
      <td>{props.index}</td>
      <td>
        <AppName name={props.app.info.name} image={props.app.info.img} packageName={props.app.package_name} />
      </td>
      <AppVersions stores={props.app.info.stores} packageName = {props.app.package_name}/>
    </tr>
  );
}

Row.propTypes = {
  index: PropTypes.number.isRequired,
  app: PropTypes.object.isRequired,
};
