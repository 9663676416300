import React from "react";
import Stores from "./components/Stores";
import { THead, TableRow } from "./styles";



export function Head() {
  return (
    <THead>
      <TableRow>
        <th>#</th>
        <th>App Name</th>
        <Stores />
      </TableRow>
    </THead>
  );
}
