import styled from "styled-components";
import { ScreenSize } from "pages/CONTANTS";

export const Selector = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 14px 16px;
  border-radius: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 152px;
  height: 48px;

  &:hover {
    cursor: pointer;
  }

  ${(props) =>
    props.active &&
    `
    background: var(--blue);

    span {
        &::before {
            background-color: var(--white);
        }
    }

    h1 {
        color: var(--white);
    }
  `}

  @media (max-width: ${ScreenSize.TABLET}px) {
    width: fit-content;

    span {
      display: none;
    }

    ${(props) =>
      props.active &&
      `
      span {
        display: flex;
      }
    `}
  }
`;

export const Flag = styled.span`
  display: flex;

  &::before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 16px;
    border-radius: 24px;
    margin-right: 12px;
  }
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--blue);
  margin: 0;
`;
