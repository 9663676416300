import styled from "styled-components";
import { ScreenSize } from "pages/CONTANTS";

export const Wrapper = styled.div`
  flex: 1;
  margin: 0 16px;
  width: 100%;
  height: 100%;
  overflow-x: auto;
`;

export const Table = styled.table`
  padding: 24px 32px 13px 24px;
  background-color: var(--white);
  border-radius: 18px;
  width: 100%;
  height: 100%;

  td:last-child {
    width: 100%;
  }

  @media (max-width: ${ScreenSize.TABLET}px) {
    flex: auto;
    border-radius: 0;
    margin: 0;
    width: 1000px;
    height: 656px;
    align-self: flex-start;
  }
`;
