import React from "react";
import PropTypes from "prop-types";
import { ButtonPropData } from "./interface";
import { Wrapper } from "./styles";

export function Button(props: ButtonPropData) {
  return <Wrapper onClick={() => props.onClickFunc()}>{props.text}</Wrapper>;
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  onClickFunc: PropTypes.func.isRequired,
};
