import React from "react";
import PropTypes from "prop-types";
import { FilterPropData } from "./interface";
import { useSelector } from "react-redux";
import { Selector, Title, Flag } from "./styles";
import { RootState } from "redux/types";

export function Filter(props: FilterPropData) {
  const activeId = useSelector((state: RootState) => state.filter.active);

  const isActive = () => {
    return activeId === props.filter.id;
  };

  return (
    <Selector
      active={isActive()}
      onClick={() => {
        props.updateState(props.filter.id);
      }}
    >
      <Flag></Flag>
      <Title>{props.filter.title}</Title>
    </Selector>
  );
}

Filter.propTypes = {
  filter: PropTypes.object.isRequired,
  updateState: PropTypes.func.isRequired,
};
