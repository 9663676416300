import { CategoryType } from "helpers/enums";
import { GET_CATEGORIES, SET_CATEGORY_ACTIVE } from "redux/CONSTANTS";
import { categoryReducerInitialState } from "redux/initialState";

export const getCategories = () => {
  return {
    type: GET_CATEGORIES,
    payload: {
      categories: categoryReducerInitialState.categories,
      active: CategoryType.SOCIAL,
    },
  };
};

export const setActiveCategory = (id: CategoryType) => {
  return {
    type: SET_CATEGORY_ACTIVE,
    payload: id,
  };
};
