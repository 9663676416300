import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { RowAppVersions } from "./interface";
import AppVersion from "./components/AppVersion";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";

export function AppVersions(props: RowAppVersions) {
  const stores = useSelector((state: RootState) => state.stores.stores);

  return (
    <Fragment>
      {Object.keys(stores).map((store: any, index: number) => (
        <AppVersion key={index} text={props.stores[store]?.version} />
      ))}
    </Fragment>
  );
}

AppVersions.propTypes = {
  stores: PropTypes.object.isRequired,
};
