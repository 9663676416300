import {
  RESET_PACKAGES_DATA_REQUEST,
  RESET_SUBSCRIPTION,
} from "redux/CONSTANTS";

export const resetPackagesDataRequest = () => {
  return {
    type: RESET_PACKAGES_DATA_REQUEST,
    payload: {
      packages: [],
      totalPages: 0,
      page: 1,
    },
  };
};

export const resetSubscription = () => {
  return {
    type: RESET_SUBSCRIPTION,
    payload: {
      subscribed: false,
    },
  };
};
