import styled from "styled-components";
import { ScreenSize } from "pages/CONTANTS";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 16px 24px 16px;
  padding: 24px 32px;
  background-color: var(--blue);
  border-radius: 18px;
  color: var(--white);

  @media (max-width: ${ScreenSize.TABLET}px) {
    border-radius: 0;
    margin: 0;
    padding: 28px 24px;
  }
`;
