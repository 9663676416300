import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";
import ButtonContainer from "./components/ButtonContainer";
import SpinnerContainer from "./components/SpinnerContainer";

export function Loader() {
  const isLoading = useSelector((state: RootState) => state.apps.isLoading);
  const packages = useSelector((state: RootState) => state.apps.packages);
  const totalPages = useSelector((state: RootState) => state.apps.totalPages);
  const page = useSelector((state: RootState) => state.apps.page);

  const displayLoadMoreButton = () => {
    if (!isLoading && packages.length > 0 && totalPages && page < totalPages) {
      return <ButtonContainer />;
    }
  };

  return (
    <Fragment>
      {isLoading ? <SpinnerContainer /> : displayLoadMoreButton()}
    </Fragment>
  );
}
