import React from "react";
import Categories from "./components/Categories";
import CategoryFilters from "./components/CategoryFilters";
import { Wrapper } from "./styles";

export function Skeleton() {
  return (
    <Wrapper>
      <CategoryFilters />
      <Categories />
    </Wrapper>
  );
}
