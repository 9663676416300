import React from "react";
import Categories from "./components/Categories";
import CategoryFilters from "./components/CategoryFilters";
import { Wrapper, Separator } from "./styles";

export function CategoryFiltersAndCategories() {
  return (
    <Wrapper>
      <CategoryFilters />
      <Separator></Separator>
      <Categories />
    </Wrapper>
  );
}
