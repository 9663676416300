import { SHOW_MODAL, HIDE_MODAL } from "redux/CONSTANTS";
import { moldalReducerInitialState } from "redux/initialState";

const modalReducer = (state = moldalReducerInitialState, action: any) => {
  switch (action.type) {
    case SHOW_MODAL:
      return {
        ...state,
        show: true,
      };

    case HIDE_MODAL:
      return {
        ...state,
        show: false,
      };

    default:
      return state;
  }
};

export default modalReducer;
