import styled from "styled-components";
import { ScreenSize } from "pages/CONTANTS";

export const Wrapper = styled.div`

display: flex;
    position: absolute;
    left: 0;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    background-color: var(--white);
    color: background: #272846;
    width: 176px;
    height: 184px;
    bottom: 36px;
    border-radius: 0px 18px 18px 0px;
    box-shadow: 0px 4px 13px 0px #00000040;



  @media (max-width: ${ScreenSize.TABLET}px) {
    position: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white);
    color: background: #272846;
    border-radius: 18px;
    padding: 24px 24px 24px 24px;
    margin: 24px 24px 0 24px;
    height: 100%;
    width: calc(100% - 46px);
    box-shadow: 0px 4px 13px 0px #00000040;
    min-height: 160px;
  }
`;
