import styled from "styled-components";
import { Selector } from "./components/Filter/styles";
import { ScreenSize } from "pages/CONTANTS";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & ${Selector} + ${Selector} {
    margin-top: 16px;
  }

  @media (max-width: ${ScreenSize.TABLET}px) {
    align-self: flex-start;
    flex-direction: row;
    justify-content: flex-start;
    padding: 0 24px;
    margin-bottom: 16px;

    & ${Selector} + ${Selector} {
      margin: 0;
      margin-left: 8px;
    }
  }
`;
