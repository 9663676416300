import styled from "styled-components";

export const TextLink = styled.span`
  display: block;
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;

  color: var(--white);
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: var(--lighterNegro);
    cursor: pointer;
  }
  width:282px;
  white-space: normal;
`;
