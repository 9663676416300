import styled from "styled-components";

export const ErrorMessage = styled.p`
  display:flex;
  flex-direction: row;
  align-items: center;
  color: #FF3A3A;
  font-size: 1rem;
  margin: 1rem 0;
  font-family: Roboto;
  font-weight: bold;
  height:16px;
  gap: 10px;
  vertical-align: middle;
`;

export const SuccessMessage = styled.p`
display: flex;
flex-direction: row;  
align-items: flex-start;
color: #25B47E;
font-size: 1rem;
margin: 1rem 0;
font-family: Roboto;
font-weight: bold;
height: 16px;
gap: 10px;
vertical-align: middle;
`;
