import { Data } from "pages/PackageDetails/components/Charts/Data";
import { appDownloadsReducerInitialState } from "redux/initialState";
import {
    CLEAN_PLOTS,
    GET_DOWNLOADS30_DATA,
    GET_TOP_GAINER_LOSER,
    GET_TOTAL_DOWNLOADS_DATA,
} from "../CONSTANTS";

const appDownloadsReducer = (state = appDownloadsReducerInitialState, action:any) => {
    switch (action.type) {
        case GET_DOWNLOADS30_DATA: 
            return {
                ...state,
                downloads30d:action.payload
            }
        case GET_TOTAL_DOWNLOADS_DATA:
            return {
                ...state,
                totalDownloads:action.payload
            } 
        case GET_TOP_GAINER_LOSER:
            return {
                ...state,
                topGainerLoser:action.payload
            }   
        case CLEAN_PLOTS:
            return{
                ...state,
                downloads30d: {
                    downloads30d: Data(false)
                  },
                  totalDownloads: {
                    totalDownloads: Data(true)
                  },
                  topGainerLoser: {
                    topGainerLoser: {
                      gainer: "",
                      loser: ""
                    }
                  }
            }


        default: return state;
    }
} 
export default appDownloadsReducer;
