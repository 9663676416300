import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import BodyView from "./BodyView";
import Skeleton from "./components/Skeleton";
import { ChartBodyContainerPropData } from "./interface";
import { Store } from "helpers/Stores";
import { RootState } from "redux/types";

export function BodyContainer(props: ChartBodyContainerPropData) {
  const isLoading = useSelector((state: RootState) => state.apps.isLoading);
  const percentages = useSelector((state: RootState) => state.apps.percentages);
  const stores = useSelector((state: RootState) => state.stores.stores);

  const getStores = () => {
    return Object.keys(percentages)
      .filter((percentage: string) => {
        return stores[percentage];
      })
      .map((percentage: string) => {
        const store = stores[percentage];

        store.distributed = percentages[percentage].distributed;
        store.updated = percentages[percentage].updated;

        store.distributedAbsolute =
          percentages[percentage].distributed_absolute;
        store.updatedAbsolute = percentages[percentage].updated_absolute;

        return store;
      })
      .sort((a: Store, b: Store) => {
        const result = b.distributed - a.distributed;
        if (result === 0) return b.updated - a.updated;
        return result;
      });
  };

  return (
    <Fragment>
      {isLoading ? (
        <Skeleton />
      ) : percentages ? (
        <BodyView stores={getStores()} showNumbers={props.showNumbers} />
      ) : null}
    </Fragment>
  );
}

BodyContainer.propTypes = {
  showNumbers: PropTypes.bool.isRequired,
};
