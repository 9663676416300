interface PercentageStore {
  updated: number;
  distributed: number;
  updated_absolute: number;
  distributed_absolute: number;
}

export class Store {
  name: string;
  distributed: number = 0;
  updated: number = 0;
  distributedAbsolute: number = 0;
  updatedAbsolute: number = 0;
  image: string;
  link: string;

  constructor(name: string, image: string, link: string) {
    this.name = name;
    this.image = image;
    this.link = link;
  }

  updateStore(store: PercentageStore) {
    this.distributed = store.distributed;
    this.updated = store.updated;
    this.distributedAbsolute = store.distributed_absolute;
    this.updatedAbsolute = store.updated_absolute;
  }
}

export interface StoreData {
  [key: string]: Store;
}

export const stores: StoreData = {
  aptoide: new Store(
    "Aptoide",
    "https://cdn6.aptoide.com/imgs/9/7/1/9711bc92b8109355d9524ec843ed1712_icon.png",
    "https://en.aptoide.com"
  ),
  googleplay: new Store(
    "Google Play",
    "https://www.ajudandroid.com.br/wp-content/uploads/2016/11/google-play-logo-3.png",
    "https://play.google.com/store"
  ),
  apkpure: new Store(
    "ApkPure",
    "https://latestandroidapp.com/wp-content/uploads/2019/12/apkpure-aps.jpg",
    "https://apkpure.com"
  ),
  nineapps: new Store(
    "9Apps",
    "https://apklatestversion.com/logo/9Apps-icon.png",
    "https://9apps.onl/homepage/"
  ),
  uptodown: new Store(
    "Uptodown",
    "https://media-exp3.licdn.com/dms/image/C4E0BAQHo7lpy2budgQ/company-logo_200_200/0/1615453451015?e=2159024400&v=beta&t=YYoJ1wIBzk6ixKhhpMe9K9Ro24Y_lbGLC5FOwDrKCak",
    "https://en.uptodown.com"
  ),
  vivo: new Store(
    "VivoStore",
    "https://i.pinimg.com/originals/c7/9d/89/c79d89828ff5f9080627a1fe95d0ff48.png",
    "https://www.vivo.com/en/"
  ),
  xiaomi: new Store(
    "Xiaomi",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Xiaomi_logo.svg/2048px-Xiaomi_logo.svg.png",
    "https://www.mi.com/in/index.html"
  ),
  cafebazaar: new Store(
    "CafeBazzar",
    "https://img1.gratispng.com/20180529/bsi/kisspng-cafe-bazaar-marketplace-android-5b0d0056b84e75.4776552615275787107549.jpg",
    "https://cafebazaar.ir"
  ),
  softonic: new Store(
    "Softonic",
    "https://upload.wikimedia.org/wikipedia/commons/7/7c/Softonic_logo_2018.png",
    "https://en.softonic.com/android"
  ),
  amazon: new Store(
    "Amazon",
    "https://techcrunch.com/wp-content/uploads/2011/09/images-screenshots-captures-amazon-appstore-logo-21032011_00b4000000001978.jpg",
    "https://www.amazon.com/gp/mas/get/amazonapp"
  ),
  gionee: new Store(
    "Gionee",
    "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.pinterest.com%2Fpin%2F510384570270415666%2F&psig=AOvVaw2D4mPSGWTJEw8LtV1WPt6D&ust=1629387524147000&source=images&cd=vfe&ved=0CAsQjRxqFwoTCKCy-9bzuvICFQAAAAAdAAAAABAD",
    "https://gionee.co.in"
  ),
  multilaser: new Store(
    "Multilaser",
    "https://iconape.com/wp-content/files/tj/350310/png/350310.png",
    "https://www.multilaser.com.br"
  ),
  cherrymobile: new Store(
    "Cherry Mobile",
    "https://pbs.twimg.com/profile_images/1086180781058211841/mG82Pilw.jpg",
    "https://www.cherrymobile.com"
  ),
  apptoko: new Store(
    "Apptoko",
    "https://www.google.com/search?client=safari&rls=en&q=app+toko&ie=UTF-8&oe=UTF-8",
    "https://www.google.com/url?sa=i&url=https%3A%2F%2Fapptoko.en.uptodown.com%2Fandroid&psig=AOvVaw10wLs2xXLVNfWnXPwswC6B&ust=1629387566509000&source=images&cd=vfe&ved=0CAsQjRxqFwoTCMD2huvzuvICFQAAAAAdAAAAABAD"
  ),
  meizu: new Store(
    "Meizu",
    "https://upload.wikimedia.org/wikipedia/commons/b/b7/Meizu_Logo.jpg",
    "https://www.meizu.com/en/"
  ),
};

export const storeComparator = function (a: Store, b: Store) {
  const result = b.distributed - a.distributed;
  if (result === 0) return b.updated - a.updated;
  return result;
};
