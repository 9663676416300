import { combineReducers } from "redux";
import categoryFilterReducer from "./categoryFilterReducer";
import categoryReducer from "./categoryReducer";
import filterReducer from "./filterReducer";
import appsReducer from "./appsReducer";
import subscriptionReducer from "./subscriptionReducer";
import modalReducer from "./modalReducer";
import storesReducer from "./storesReducer";
import searchReducer from "./searchReducer";
import appDownloadsReducer from "./appDownloadsReducer";

const rootReducer = combineReducers({
  apps: appsReducer,
  filter: filterReducer,
  category: categoryReducer,
  categoryFilter: categoryFilterReducer,
  subscription: subscriptionReducer,
  modal: modalReducer,
  stores: storesReducer,
  search: searchReducer,
  appDownloads: appDownloadsReducer,
});

export default rootReducer;
