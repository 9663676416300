import styled from "styled-components";

export const TFoot = styled.tfoot`
  color: var(--mediumGray);
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 9px;
  line-height: 10px;
  width: 37px;
  margin-right: 15px;

  &:hover {
    cursor: pointer;
    color: var(--lightGray);
  }
`;
