import "./App.css";
import "fontsource-roboto";
import { Provider } from "react-redux";
import store from "redux/store";
import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { RouterConfig } from "./navigation/RouterConfig";
import Navbar from "./components/Navbar";
import ViewportProvider from "hooks/viewport/ViewportProvider";

function App() {
  useEffect(() => {
    document.title = "App Store Barometer";
  }, []);

  return (
    <Provider store={store}>
      <ViewportProvider>
        <BrowserRouter>
          <Navbar />
          <RouterConfig />
        </BrowserRouter>
      </ViewportProvider>
    </Provider>
  );
}

export default App;
