import {
  FETCHING_APPS_DATA,
  FETCH_APPS_ERROR,
  ALL_APPS_PACKAGE_AND_PERCENTAGE_DATA,
  TOP_APPS_PACKAGE_PERCENTAGE_DATA,
  CATEGORIES_APPS_PACKAGE_AND_PERCENTAGE_DATA,
  ALL_APPS_PACKAGE_DATA,
  CATEGORIES_APPS_PACKAGE_DATA,
  TOP_APPS_PACKAGE_DATA,
  CHANGE_PAGE,
  RESET_PACKAGES_DATA_REQUEST,
  GET_APP_DATA_BY_SEARCH,
  GET_APP_DATA_BY_SEARCH_APPEND,
  ALL_APPS_PACKAGE_DATA_RESET,
  CATEGORIES_APPS_PACKAGE_DATA_APPEND,
  ALL_APPS_PACKAGE_DATA_APPEND,
} from "../CONSTANTS";
import { appsReducerInitialState } from "../initialState";

const appsReducer = (state = appsReducerInitialState, action: any) => {
  switch (action.type) {
    case FETCHING_APPS_DATA:
      return {
        ...state,
        isLoading: true,
      };

    case FETCH_APPS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case CHANGE_PAGE:
      return {
        ...state,
        page: action.payload,
      };

    case RESET_PACKAGES_DATA_REQUEST:
      return {
        ...state,
        packages: action.payload.packages,
        totalPages: action.payload.totalPages,
        page: action.payload.page,
      };

    case ALL_APPS_PACKAGE_AND_PERCENTAGE_DATA:
      return {
        ...state,
        isLoading: false,
        percentages: action.payload.percentages,
        totalApps: action.payload.totalApps,
      };

    case ALL_APPS_PACKAGE_DATA:
      return {
        ...state,
        isLoading: false,
        packages: action.payload.packages,
        totalPages: action.payload.totalPages,
      };

    case ALL_APPS_PACKAGE_DATA_APPEND:
      return {
        ...state,
        isLoading: false,
        packages: state.packages.concat(action.payload.packages),
        totalPages: action.payload.totalPages,
      };

    case ALL_APPS_PACKAGE_DATA_RESET:
      return {
        ...state,
        isLoading: false,
        packages: [],
        totalPages: 0,
      };


    case TOP_APPS_PACKAGE_PERCENTAGE_DATA:
      return {
        ...state,
        isLoading: false,
        percentages: action.payload.percentages,
        totalApps: action.payload.totalApps,
      };

    case TOP_APPS_PACKAGE_DATA:
      return {
        ...state,
        isLoading: false,
        packages: action.payload.packages,
      };

    case CATEGORIES_APPS_PACKAGE_AND_PERCENTAGE_DATA:
      return {
        ...state,
        isLoading: false,
        percentages: action.payload.percentages,
        totalApps: action.payload.totalApps,
      };

    case CATEGORIES_APPS_PACKAGE_DATA:
      return {
        ...state,
        isLoading: false,
        packages: action.payload.packages,
        totalPages: action.payload.totalPages,
        page: 1
      };

    case CATEGORIES_APPS_PACKAGE_DATA_APPEND:
      return {
        ...state,
        isLoading: false,
        packages: state.packages.concat(action.payload.packages),
        totalPages: action.payload.totalPages,
      };

    case GET_APP_DATA_BY_SEARCH:
      return {
        ...state,
        isLoading: false,
        packages: action.payload.packages,
        totalPages: action.payload.totalPages,
        page: 1
      }

    case GET_APP_DATA_BY_SEARCH_APPEND:
      return {
        ...state,
        isLoading: false,
        packages: state.packages.concat(action.payload.packages),
        totalPages: action.payload.totalPages,
      }

    default:
      return state;
  }
};

export default appsReducer;
