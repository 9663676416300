import React from "react";
import PropTypes from "prop-types";
import { ImagePropData } from "./interface";

export function Image(props: ImagePropData) {
  const onError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    return ((event.target as HTMLImageElement).style.visibility = "hidden");
  };

  const onMouseOver = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    return props.hoverSrc
      ? ((event.target as HTMLImageElement).src = props.hoverSrc)
      : null;
  };

  const onMouseOut = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    return props.hoverSrc
      ? ((event.target as HTMLImageElement).src = props.src)
      : null;
  };

  return (
    <img
      src={props.src}
      alt={props.alt}
      onError={onError}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      width={props.width}
      height={props.height}
    />
  );
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  hoverSrc: PropTypes.string,
};
