import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { viewportContext } from "../viewportContext";
import { ViewportProviderPropData } from "./interface";

export function ViewportProvider(props: ViewportProviderPropData) {
  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <viewportContext.Provider value={{ width }}>
      {props.children}
    </viewportContext.Provider>
  );
}

ViewportProvider.propTypes = {
  children: PropTypes.string.isRequired,
};
