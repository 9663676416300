import React from "react";
import PropTypes from "prop-types";
import { ChartHeadPropData } from "../../interface";
import { Title } from "./styles";
import Header from "./components/Header";

export function CategoriesHead(props: ChartHeadPropData) {
  return (
    <tr>
      <Title colSpan={2}>{props.activeFilter.title}</Title>
      <Header />
    </tr>
  );
}

CategoriesHead.propTypes = {
  activeFilter: PropTypes.object.isRequired,
};
