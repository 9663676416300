import {
  EMAIL_SUBSCRIPTION,
  POSTING_SUBSCRIPTION_DATA,
  POST_SUBSCRIPTION_ERROR,
  RESET_SUBSCRIPTION,
} from "redux/CONSTANTS";
import { subscriptionReducerInitialState } from "redux/initialState";

const subscriptionReducer = (
  state = subscriptionReducerInitialState,
  action: any
) => {
  switch (action.type) {
    case POSTING_SUBSCRIPTION_DATA:
      return {
        ...state,
        isLoading: true,
      };

    case POST_SUBSCRIPTION_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case EMAIL_SUBSCRIPTION:
      return {
        ...state,
        isLoading: false,
        subscribed: action.payload.subscribed,
      };

    case RESET_SUBSCRIPTION:
      return {
        ...state,
        subscribed: action.payload.subscribed,
        error: null,
      };

    default:
      return state;
  }
};

export default subscriptionReducer;
