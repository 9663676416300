import React from "react";
import PropTypes from "prop-types";
import { TooltipPropData } from "./interface";
import { Tag } from "./styles";

export function Tooltip(props: TooltipPropData) {
  return <Tag className="tooltip">{props.data}</Tag>;
}

Tooltip.propTypes = {
  data: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};
