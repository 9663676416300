import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { TextLink } from "./styles";
import { CategoryType, FilterType } from "helpers/enums";
import { RootState } from "redux/types";
import { useHistory } from "react-router-dom";
import { TextRedirectPropData } from "./interface";

export function TextRedirect(props: TextRedirectPropData) {
  const activeFilterId = useSelector((state: RootState) => state.filter.active);
  const activeCategoryId = useSelector(
    (state: RootState) => state.category.active
  );

  const history = useHistory();

  const getDetailsRoute = () => {

    return "/package_details/" + props.packageName;
  };

  const animateGoToDetailsRoute = () => {
    if (!props.setAnimating) {
      history.push(getDetailsRoute());
      return;
    }

    props.setAnimating?.(true);

    setTimeout(() => {
      history.push(getDetailsRoute());
    }, 1000);
  };

  return (
    <TextLink onClick={animateGoToDetailsRoute}>{props.name}</TextLink>
  );
}

TextRedirect.propTypes = {
  setAnimating: PropTypes.func,
  packageName: PropTypes.string,
  name:PropTypes.string,
};
