import React from "react";
import PropTypes from "prop-types";
import { AppVersionPropData } from "./interface";


export function AppVersion(props: AppVersionPropData) {
  switch(props.store) {
    case "aptoide": return <td>{props.text && props.store ? <a target="_blank" href={"https://pt.aptoide.com/search?query=" + props.packageName } rel="noreferrer">{props.text}</a> : "N/A"}</td>;
    case "googleplay": return <td>{props.text && props.store ? <a target="_blank" href={"https://play.google.com/store/apps/details?id=" + props.packageName } rel="noreferrer">{props.text}</a> : "N/A"}</td>;
    case "apkpure": return <td>{props.text && props.store ? <a target="_blank" href={"https://apkpure.com/gmail/" + props.packageName } rel="noreferrer">{props.text}</a> : "N/A"}</td>;
    case "cafebazaar": return <td>{props.text && props.store ? <a target="_blank" href={"https://cafebazaar.ir/app/" + props.packageName } rel="noreferrer">{props.text}</a> : "N/A"}</td>;
    case "meizu": return <td>{props.text && props.store ? <a target="_blank" href={"https://app.meizu.com/apps/public/detail?package_name=" + props.packageName } rel="noreferrer">{props.text}</a> : "N/A"}</td>;


    default: return <td>{props.text ? props.text : "N/A"}</td>;
  }
  
}

AppVersion.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  packageName: PropTypes.string,
};
