import React from "react";
import { Wrapper, Title, TextWrapper, Text, BoldText, Email } from "./styles";
import EmailForm from "./components/EmailForm";

export function RightContainer() {
  return (
    <Wrapper>
      <Title>About App Store Barometer</Title>
      <TextWrapper>
        <Text>
          The App Store Barometer is a tool to provide useful information about
          the content found in Android app stores around the world. We believe
          that the information related to the number of distributed apps and
          which ones of those are being distributed on their latest version can
          be useful to provide a wide picture of the Android distribution
          environment.
        </Text>
        <Text>
          This information can be used by Android users and app distributors to
          compare app stores and decide which one is the best fit for their
          needs; either downloading apps or distributing them.
        </Text>
        <Text>
          The App Store Barometer is brought to you by Catappult, a platform
          that helps app developers and publishers to reach Android users via
          third-party app stores.
        </Text>
        <Text>
          If you have any question regarding our project, please reach us
          through email at <Email>support@catappult.io</Email>.
        </Text>
      </TextWrapper>
    </Wrapper>
  );
}
