import React from "react";
import { useDispatch } from "react-redux";
import Button from "components/Button";
import { Wrapper } from "./styles";
import { changePage } from "redux/actions";
import { continueSearch } from "redux/actions/searchActions";

export function ButtonContainer() {
  const dispatch = useDispatch();

  const nextPage = () => {
    dispatch(continueSearch())
    dispatch(changePage());
  };

  return (
    <Wrapper>
      <Button text="Load More" onClickFunc={nextPage} />
    </Wrapper>
  );
}
