import React from "react";
import PropTypes from "prop-types";
import { Wrapper } from "./styles";
import { RowBarPropData } from "./interface";
import Unit from "./components/Unit";

export function Bar(props: RowBarPropData) {
  const latestVersion = () => {
    return {
      percentage: props.store.updated,
      absolute: props.store.updatedAbsolute,
    };
  };

  const previousVersions = () => {
    return {
      percentage: props.store.distributed - props.store.updated,
      absolute: props.store.distributedAbsolute - props.store.updatedAbsolute,
    };
  };

  return (
    <Wrapper>
      <Unit
        data={latestVersion()}
        color="orange"
        showNumbers={props.showNumbers}
      />
      <Unit
        data={previousVersions()}
        color="lightGray"
        showNumbers={props.showNumbers}
      />
    </Wrapper>
  );
}

Bar.propTypes = {
  store: PropTypes.object.isRequired,
  showNumbers: PropTypes.bool.isRequired,
};
