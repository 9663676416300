import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { RootState } from "redux/types";
import { ParamCategoryType, ParamFilterType } from "helpers/enums";
import { getStores, resetPackagesDataRequest,  } from "redux/actions";
import { DetailsParams } from "./interface";
import DetailsView from "./DetailsView";
import { cleanPlots, getAppDownloads } from "redux/actions/appDownloadsActions";

export function DetailsContainer() {
  const { packageName } = useParams<DetailsParams>();
  const error = useSelector((state: RootState) => state.apps.error);
  const dispatch = useDispatch();
  const filter = ParamFilterType.ALL_APPS

  useEffect(() => {
    dispatch(getStores());
    dispatch(cleanPlots())
    dispatch(getAppDownloads(packageName, "downloads_30d"))
    dispatch(getAppDownloads(packageName, "downloads_by_store"))
    dispatch(getAppDownloads(packageName, "top_gainer_loser"))

    return () => {
      dispatch(resetPackagesDataRequest());
    };
  }, []);

  return (
    <div>
      {error ? (
        <div>Error</div>
      ) : (
        <div>
        <DetailsView filter={filter}/>
        </div>
      )}
    </div>
  );
}
