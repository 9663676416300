import styled from "styled-components";
import { fadeInAnimation } from "helpers/animations";

export const Title = styled.h1`
  font-weight: 700;
  font-size: 35px;
  line-height: 28px;
  white-space: nowrap;
  margin-top: 4px;
  margin-bottom: 11px;
  -webkit-animation: ${fadeInAnimation()} 1.2s
    cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: ${fadeInAnimation()} 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
`;
