import React from "react";
import Footer from "../../common/Sidebar/Footer";
import { Wrapper } from "./styles";

export function SidebarFooter() {
  return (
    <Wrapper>
      <Footer />
    </Wrapper>
  );
}
