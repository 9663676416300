import { FilterType } from "helpers/enums";
import { GET_FILTERS, SET_FILTER_ACTIVE } from "redux/CONSTANTS";
import { filterReducerInitialState } from "redux/initialState";

export const getFilters = () => {
  return {
    type: GET_FILTERS,
    payload: {
      filters: filterReducerInitialState.filters,
      active: FilterType.ALL_APPS,
    },
  };
};

export const setActiveFilter = (id: FilterType) => {
  return {
    type: SET_FILTER_ACTIVE,
    payload: id,
  };
};
