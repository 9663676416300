import FileSaver from "file-saver";
import { stores } from "helpers/Stores";
import useViewport from "hooks/viewport/useViewport";
import html2canvas from "html2canvas";
import { ScreenSize } from "pages/CONTANTS";
import React, { useRef } from "react";
import { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Line } from "recharts";
import { useCurrentPng } from "recharts-to-png";
import { getStores } from "redux/actions";
import { RootState } from "redux/types";
import { DownloadsChart } from "./Chart/DownloadsChart";
import { StoresChart } from "./Chart/StoresChart";
import { Data } from "./Data";
import { ChartsParams, DetailsParams, TotalDownloadsData } from "./interface";
import { AppTxt, BigBox, BottomBoxes, BottomSubDiv, Box, BoxLine, ButtonDiv, ChartBox, ChartBox2, ChartsBox, ChartSkel, Filter, MiniBox, StyledButton, StyledSubDivTxt, SubBox, Title, TitleSkel, VerticalLine } from "./styles";


export function getColor(store: string) {

  switch(store) {
    case"aptoide":
     return "#FE9150"
    case"googleplay":
     return "#C7C7C7" 
    case"apkpure":
     return "#5BC66E" 
    case"amazon":
     return "#F2A741"
    case"xiaomi":
     return "#ED712E" 
    case"nineapps":
     return "#A82C22" 
    case"gionee":
     return "#AE643A" 
    case"multilaser":
     return "#000000" 
    case"cherrymobile":
     return "#9B9B9B" 
    case"apptoko":
     return "#367AB7" 
    case"meizu":
     return "#69B7DD" 
    case"cafebazaar":
     return "#9CD057" 
    case"softonic":
     return "#565656" 
    case"taptap":
     return "#56B7C6" 
    case"huawei":
     return "#C34044" 
    case"vivo":
     return "#BF7DF3"
    case"uptodown":
      return "#6DA8DC"
     default:
      return "#4884d8"
  }

}

export function Charts() {

  const packages = useSelector((state: RootState) => state.apps.packages);
  const stores = useSelector((state: RootState) => state.stores.stores)
  const data00 = useSelector((state: RootState) => state.appDownloads.downloads30d);
  const data03 = useSelector((state: RootState) => state.appDownloads.totalDownloads);
  const [filter, setFilter] = useState("All Stores");
  const [activeButton, setActiveButton] = useState("all");

  const data01 = data00.downloads30d;
  const data02:TotalDownloadsData = data03.totalDownloads.filter((data : any) => data.name != "nineapps");
  const data04= Object.values(data02).filter(({value})=> value != "N/A")
 
  const {packageName, download} = useParams<DetailsParams>();

  const [isDownloaded, setIsDownloaded] = useState(false)

  const { width } = useViewport();

  const mobile = width <=ScreenSize.TABLET ? true : false

  const storesImg = Object.values(stores).map((store:any) =>  {
    return store.image
  })

  function getStoreImg (name:string):string {

    const temp = Object.keys(stores).map((store:any, index:number) => {
      if(store == name) {
        return index
      }
      else {
        return -1
      }
    })
    const temp2 = temp.filter((item:number) => item > -1)
    return String(storesImg[temp2[0]])
  }



      const finalPackage = packages.map((app: any, index: number) => (
      app.info.name
    ))


    
    const finalTotal = Object.values(data04).reduce((total, obj) => total + obj.value, 0)

    const gpTotal = Object.values(data02).filter(({name}) => name == "googleplay").reduce((total, obj) => obj.value == "N/A" ? total + 0 : total + obj.value, 0)

    const gpPerc = Math.round(( gpTotal / finalTotal) * 100)

    const storesPerc = Math.round(((finalTotal - gpTotal) / finalTotal) * 100)

    const gainerName = useSelector((state: RootState) => state.appDownloads.topGainerLoser.topGainerLoser.gainer);
    const loserName = useSelector((state: RootState) => state.appDownloads.topGainerLoser.topGainerLoser.loser);


    const printRef = useRef<HTMLElement>(null);
    const printRef2 = useRef<HTMLElement>(null);

    const delay = (ms: number | undefined) => new Promise(res => setTimeout(res, ms));

    const handleDownload30d = async () => {
      await delay(5000)
      if (printRef.current == null) {
        return
      }
      const element = printRef.current;
      const canvas = await html2canvas(element);
  
      const data = canvas.toDataURL('image/jpg');
      const link = document.createElement('a');
  
      if (typeof link.download === 'string') {
        link.href = data;
        link.download = 'downloads30d.jpg';
  
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    };



    const handleTotalDownloads = async () => {
      await delay(5000)
      if (printRef2.current == null) {
        return
      }
      const element = printRef2.current;
      const canvas = await html2canvas(element);
  
      const data = canvas.toDataURL('image/jpg');
      const link = document.createElement('a');
  
      if (typeof link.download === 'string') {
        link.href = data;
        link.download = 'totalDownloads.jpg';
  
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    };


    if(!isDownloaded && download == "download") {
      if(loserName != "") {
        handleDownload30d()
        handleTotalDownloads()
        setIsDownloaded(true);
      }
    }

        const mystyle = {
      color: "white",
      backgroundColor: "DodgerBlue",
      padding: "10px",
      fontFamily: "Arial"
    };
    
  
  
    

  if(loserName != "") {

      return (
    <ChartsBox mobile={mobile}>
        <BigBox mobile={mobile} >
          <ChartBox >
          <Title><b>{finalPackage}</b> downloads in the last 30 days - {filter}</Title>
            <ButtonDiv>
              <Filter>Filter by:</Filter>
              <StyledButton active={activeButton == "all" ? true : false} onClick={() => {setFilter("All Stores"); setActiveButton("all")}}>Show All</StyledButton>
              <StyledButton active={activeButton == "topG" ? true : false} onClick={() => {setFilter("Top Gainer"); setActiveButton("topG")}}>Top Gainer</StyledButton>
              <StyledButton active={activeButton == "topL" ? true : false} onClick={() => {setFilter("Top Loser"); setActiveButton("topL")}}>Top Loser</StyledButton>
            </ButtonDiv>
            <ChartBox2 ref={printRef}>
              <DownloadsChart filter={filter} data={data01}/>
            </ChartBox2>
          </ChartBox>
          <BottomBoxes>
            <BottomSubDiv>
              <StyledSubDivTxt>Top Gainer</StyledSubDivTxt>
              <AppTxt><img src={getStoreImg(gainerName)} alt="" width={16} height={16}/> {gainerName} </AppTxt>
            </BottomSubDiv>
            <VerticalLine/>
            <BottomSubDiv>
              <StyledSubDivTxt>Top Loser</StyledSubDivTxt>
              <AppTxt><img src={getStoreImg(loserName)} alt="" width={16} height={16}/> {loserName} </AppTxt>
            </BottomSubDiv>
          </BottomBoxes>
        </BigBox>
        <BigBox mobile={mobile} >
          <ChartBox>
          <Title><b>{finalPackage}</b> total downloads in the last 30 days </Title>
          <ChartBox2 ref={printRef2}>
            <StoresChart data={data02} totalDownloads={finalTotal}/>
          </ChartBox2>
          </ChartBox>
          <BottomBoxes>
            <BottomSubDiv>
              <StyledSubDivTxt>{gpPerc}%</StyledSubDivTxt>
              <AppTxt><img src={getStoreImg("googleplay")} alt="GP" width={16} height={16}/> Google Play </AppTxt>
            </BottomSubDiv>
            <VerticalLine/>
            <BottomSubDiv>
              <StyledSubDivTxt>{storesPerc}%</StyledSubDivTxt>
              <AppTxt>Third Party </AppTxt>
            </BottomSubDiv>
          </BottomBoxes>
        </BigBox>
      </ChartsBox>

      );
      }
    else {
      return(
        <ChartsBox mobile={mobile}>
          <BigBox mobile={mobile}>
            <ChartBox skel={true}>
              <TitleSkel/>
              <ChartSkel/>
            </ChartBox>
          </BigBox>
          <BigBox mobile={mobile}>
            <ChartBox skel={true}>
              <TitleSkel/>
              <ChartSkel/>
            </ChartBox>
          </BigBox>
        </ChartsBox>
      )
    }
}

