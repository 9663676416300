import { PER_PAGE } from "helpers/CONTANTS";
import { fetchData } from "services/api";
import {
  GET_DOWNLOADS_DATA,
  GET_PACKAGES,
  GET_PACKAGES_BY_CATEGORY,
  GET_PACKAGE_PERCENTAGE,
  GET_PACKAGE_PERCENTAGE_BY_CATEGORY,
  GET_PERCENTAGE_TOP_APPS,
  GET_TOP_APPS,
  SEARCH_PACKAGES,
} from "services/CONTANTS";
import { QueryParam } from "services/interface";

const defaultQuery: QueryParam[] = [
  new QueryParam("page", "1"),
  new QueryParam("per_page", PER_PAGE.toString()),
];

export const getPackagePercentageData = (queryParams: QueryParam[] = []) => {
  return fetchData(GET_PACKAGE_PERCENTAGE, queryParams);
};

export const getPackagesData = (queryParams: QueryParam[] = defaultQuery) => {
  return fetchData(GET_PACKAGES, queryParams);
};

export const getTopAppsPercentageData = (queryParams: QueryParam[] = []) => {
  return fetchData(GET_PERCENTAGE_TOP_APPS, queryParams);
};

export const getTopAppsData = (queryParams: QueryParam[] = []) => {
  return fetchData(GET_TOP_APPS, queryParams);
};

export const getPackagePercentageByCategoryData = (
  queryParams: QueryParam[] = []
) => {
  return fetchData(GET_PACKAGE_PERCENTAGE_BY_CATEGORY, queryParams);
};

export const getPackagesByCategoryData = (queryParams: QueryParam[] = []) => {
  return fetchData(GET_PACKAGES_BY_CATEGORY, queryParams);
};

export const searchPackages = (queryParams: QueryParam[] = []) => {
  return fetchData(SEARCH_PACKAGES, queryParams);
};

export const getDownloadsData = (queryParams: QueryParam[] = []) => {
  return fetchData(GET_DOWNLOADS_DATA, queryParams);
};
