import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FilterData } from "helpers/interfaces";
import Filter from "./components/Filter";
import { Wrapper } from "./styles.js";
import { RootState } from "redux/types";
import {
  getAllAppsPercentageAndData,
  getCategoriesAppsPercentageAndData,
  getFilters,
  setActiveFilter,
  getTopAppsPercentage,
} from "redux/actions";
import { FilterType } from "helpers/enums";

export function Filters() {
  const filters = useSelector((state: RootState) => state.filter.filters);
  const activeFilterId = useSelector((state: RootState) => state.filter.active);
  const activeCategoryId = useSelector(
    (state: RootState) => state.category.active
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (filters.length <= 0) {
      dispatch(getFilters());
    }
  }, []);

  useEffect(() => {
    switch (activeFilterId) {
      case FilterType.ALL_APPS:
        dispatch(getAllAppsPercentageAndData());
        break;
      case FilterType.TOP_APPS:
        dispatch(getTopAppsPercentage());
        break;
      case FilterType.CATEGORIES:
        break;
      default:
        dispatch(getAllAppsPercentageAndData());
        break;
    }
  }, [activeFilterId]);

  const updateFilterState = (id: FilterType) => {
    dispatch(setActiveFilter(id));
  };

  return (
    <Wrapper>
      {filters.map((filter: FilterData, index: number) => {
        return (
          <Filter filter={filter} updateState={updateFilterState} key={index} />
        );
      })}
    </Wrapper>
  );
}
