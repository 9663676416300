import React from "react";
import PropTypes from "prop-types";
import { ChartHeadPropData } from "../../interface";
import { Title, Subtitle } from "./styles";

export function TopAppsHead(props: ChartHeadPropData) {
  return (
    <tr>
      <Title colSpan={2}>{props.activeFilter.title}</Title>
      <Subtitle>Top 20 apps in Aptoide</Subtitle>
    </tr>
  );
}

TopAppsHead.propTypes = {
  activeFilter: PropTypes.object.isRequired,
};
