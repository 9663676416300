import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";
import Skeleton from "./components/Skeleton";
import { Title } from "./styles";
import { numberFormat } from "helpers/numbers";

export function TotalApps() {
  const totalApps = useSelector((state: RootState) => state.apps.totalApps);
  const isLoading = useSelector((state: RootState) => state.apps.isLoading);

  return (
    <Fragment>
      {isLoading ? (
        <Skeleton />
      ) : totalApps ? (
        <Title>{numberFormat(totalApps)}</Title>
      ) : null}
    </Fragment>
  );
}
