import React, { createContext } from "react";

interface viewportContextData {
  width: number;
}

const initialValue: viewportContextData = {
  width: window.innerWidth,
};

export const viewportContext: React.Context<viewportContextData> =
  createContext(initialValue);
