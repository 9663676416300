import React from "react";
import Label from "components/Label";
import { Wrapper } from "./styles";

export function Labels() {
  return (
    <Wrapper>
      <Label text="Latest version" color="orange" />
      <Label text="Previous versions" color="gray" />
      <Label text="Not distributed" color="lightgray" />
    </Wrapper>
  );
}
