import React, { useState } from "react";
import Header from "../../common/Sidebar/Header";
import Footer from "../../common/Sidebar/Footer";
import ChartKey from "../../common/Sidebar/ChartKey";
import { AnimationWrapper, Wrapper } from "./styles";

export function Sidebar() {
  const [animating, setAnimating] = useState<boolean>(false);

  return (
    <Wrapper>
      <AnimationWrapper animating={animating}></AnimationWrapper>
      <Header setAnimating={setAnimating} />
      <ChartKey />
      <Footer />
    </Wrapper>
  );
}
