import { PER_PAGE } from "helpers/CONTANTS";
import { getPackagePercentageData, getPackagesData } from "services";
import { searchPackages } from "services/calls";
import { QueryParam } from "services/interface";
import {
  FETCHING_APPS_DATA,
  FETCH_APPS_ERROR,
  ALL_APPS_PACKAGE_AND_PERCENTAGE_DATA,
  ALL_APPS_PACKAGE_DATA,
  GET_APP_DATA_BY_SEARCH,
  RESET_PACKAGES_DATA_REQUEST,
  GET_APP_DATA_BY_SEARCH_APPEND,
  ALL_APPS_PACKAGE_DATA_RESET,
  ALL_APPS_PACKAGE_DATA_APPEND,
} from "../CONSTANTS";

export const getAllAppsPercentageAndData = () => {
  return (dispatch: any) => {
    dispatch({
      type: FETCHING_APPS_DATA,
    });

    Promise.all([getPackagePercentageData(), getPackagesData()])
      .then((res: any) => {
        dispatch({
          type: ALL_APPS_PACKAGE_AND_PERCENTAGE_DATA,
          payload: {
            percentages: res[0].percentages,
            totalApps: res[1].total_apps,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_APPS_ERROR,
          payload: error,
        });
      });
  };
};

export const getAllAppsData = (page: number, append: boolean = false, perPage: number = PER_PAGE) => {
  const query: QueryParam[] = [
    new QueryParam("page", page.toString()),
    new QueryParam("per_page", perPage.toString()),
  ];

  return (dispatch: any) => {
    dispatch({
      type: FETCHING_APPS_DATA,
    });

    getPackagesData(query)
      .then((res: any) => {
        dispatch({
          type: append ? ALL_APPS_PACKAGE_DATA_APPEND : ALL_APPS_PACKAGE_DATA,
          payload: {
            packages: res.packages,
            totalPages: res.total_pages,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_APPS_ERROR,
          payload: error,
        });
      });
  };
};

export const getAllAppsByName = (name: string, page: number, append: boolean = false, exactSearch: boolean = false, perPage: number = PER_PAGE) => {
  const query: QueryParam[] = [
    new QueryParam("query", name),
    new QueryParam("page", page.toString()),
    new QueryParam("per_page", perPage.toString()),
    new QueryParam("exact_search", exactSearch.toString()),
  ];

  return (dispatch: any) => {
    dispatch({
      type: FETCHING_APPS_DATA,
    })
    searchPackages(query)
      .then((res: any) => {
        dispatch({
          type: append ? GET_APP_DATA_BY_SEARCH_APPEND : GET_APP_DATA_BY_SEARCH,
          payload: {
            packages: res.packages,
            totalPages: res.total_pages,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_APPS_ERROR,
          payload: error,
        });
      });
  }
};




export const getAllAppsDataReset = (page: number, perPage: number = PER_PAGE) => {
  const query: QueryParam[] = [
    new QueryParam("page", page.toString()),
    new QueryParam("per_page", perPage.toString()),
  ];

  return (dispatch: any) => {
    dispatch({
      type: FETCHING_APPS_DATA,
    });

    getPackagesData(query)
      .then((res: any) => {
        dispatch({
          type: ALL_APPS_PACKAGE_DATA_RESET,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_APPS_ERROR,
          payload: error,
        });
      });
  };
};

