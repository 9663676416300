import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import Head from "./components/Head";
import Body from "./components/Body";
import Loader from "./components/Loader";
import { Wrapper, Container, StoreName, StoresContainer, Versions, StoreVersion, ButtonBox, StoresSkel } from "./styles";
import { RootState } from "redux/types";
import { DetailsTablePropData, TableParams } from "./interface";
import { ParamCategoryType, ParamFilterType } from "helpers/enums";
import {
  getAllAppsData,
  getCategoriesAppsData,
  getPopularAppsData,
} from "redux/actions";
import SearchBar from "components/SearchBar/SearchBar";
import { searchPackages } from "services/calls/packages";
import { QueryParam } from "services/interface";
import { getAllAppsByName, getAllAppsDataReset } from "redux/actions/allAppsActions";
import { toCancelClean, toCleanSearch } from "redux/actions/searchActions";
import { useParams } from "react-router-dom";
import AppVersion from "./components/Body/components/Rows/components/Row/components/AppVersions/components/AppVersion";
import Store from "./components/Head/components/Stores/components/Store";
import { ExpandButton } from "pages/PackageDetails/styles";
import { ButtonDiv } from "../Charts/styles";
import PackageDetails from "pages/PackageDetails";











export function Table(props: DetailsTablePropData) {
  const { packageName } = useParams<TableParams>();
  const page = useSelector((state: RootState) => state.apps.page);
  const dispatch = useDispatch();
  const [isExpanded, setIsExpanded] = useState(false)


  useEffect(() => {
    dispatch(getAllAppsByName(packageName, page, false, true));
    dispatch(toCleanSearch());

  },[]);

  const stores = useSelector((state:RootState) => state.stores.stores)

  const packges = useSelector((state:RootState) => state.apps.packages)

  const array = [{1:""},{2:""},{3:""},{4:""},{5:""},{6:""},{7:""},{8:""}]
  
  if(stores.length != 0 && packges.length != 0)  {
    return (

      <Wrapper>
        {
            (stores && packges.length != 0) &&
            <StoresContainer isExpanded={isExpanded}>
              {
              Object.keys(stores).map((store:any, index:number) => <StoreVersion>
                <Store name={store} image={stores[store].image}/>
                <AppVersion text={packges[0].info.stores[store]?.version} store={store} packageName={packageName}/>
              </StoreVersion>)
            }
            </StoresContainer> 
            
          }
          {
        <ButtonBox>
          <ExpandButton onClick={() => isExpanded ? setIsExpanded(false) : setIsExpanded(true)
          }>{isExpanded ? "See Less Stores" : "See All Stores"}</ExpandButton>
        </ButtonBox>
    }
      </Wrapper>
    );

  }
  else {
    return (

      <Wrapper>
        {
            <StoresContainer isExpanded={isExpanded}>
              {
              Object.keys(array).map((store:any, index:number) => <StoreVersion>
                <StoreVersion>
                  <StoresSkel/>
                </StoreVersion>
              </StoreVersion>)
            }
            </StoresContainer> 
            
          }
          {
        <ButtonBox>
          <ExpandButton onClick={() => isExpanded ? setIsExpanded(false) : setIsExpanded(true)
          }>{isExpanded ? "See Less Stores" : "See All Stores"}</ExpandButton>
        </ButtonBox>
    }
      </Wrapper>
    );
  }


}

Table.propTypes = {
  filter: PropTypes.string.isRequired,
  category: PropTypes.string,
};
