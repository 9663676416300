import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 2px 16px;
  border-radius: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  border: 1px solid var(--lightGray);
  margin: 2px 4px;

  &:hover {
    cursor: pointer;
  }

  ${(props) =>
    props.active &&
    `
    background: var(--blue);
    border: 1px solid var(--blue);
    

    h1 {
        color: var(--white);
    }
  `}
`;

export const Title = styled.h1`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: var(--blue);
  text-align: center;
  white-space: nowrap;
  margin: 0;
`;
