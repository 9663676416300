import React from "react";
import PoweredLogo from "components/PoweredLogo";
import { Wrapper, Info, AboutLink } from "./styles";

export function Footer() {
  return (
    <Wrapper>
      <Info>
        Want to know more about AppStore Barometer? Click{" "}
        <AboutLink to="/about">here!</AboutLink>
      </Info>
      <PoweredLogo />
    </Wrapper>
  );
}
