export enum FilterType {
  ALL_APPS,
  CATEGORIES,
  TOP_APPS,
}

export enum CategoryFilterType {
  APPS,
  GAMES,
}

export enum CategoryType {
  SOCIAL = "SOCIAL",
  COMMUNICATION = "COMMUNICATION",
  TOOLS = "TOOLS",
  VIDEO_PLAYERS = "VIDEO_PLAYERS",
  GAME_ACTION = "GAME_ACTION",
  GAME_ADVENTURE = "GAME_ADVENTURE",
  GAME_STRATEGY = "GAME_STRATEGY",
  GAME_SPORTS = "GAME_SPORTS",
}

export enum ParamFilterType {
  ALL_APPS = "all",
  CATEGORIES = "categories",
  TOP_20 = "top_20",
}

export enum ParamCategoryType {
  SOCIAL = "SOCIAL",
  COMMUNICATION = "COMMUNICATION",
  TOOLS = "TOOLS",
  VIDEO_PLAYERS = "VIDEO_PLAYERS",
  GAME_ACTION = "GAME_ACTION",
  GAME_ADVENTURE = "GAME_ADVENTURE",
  GAME_STRATEGY = "GAME_STRATEGY",
  GAME_SPORTS = "GAME_SPORTS",
}
