import React from "react";
import PropTypes from "prop-types";
import { ChartRowLogoPropData } from "./interface";
import { Wrapper, Name } from "./styles";
import Image from "components/Image";

export function Logo(props: ChartRowLogoPropData) {
  return (
    <Wrapper>
      <Image src={props.image} alt={props.name} width={16} height={16} />
      <Name>{props.name}</Name>
    </Wrapper>
  );
}

Logo.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};
