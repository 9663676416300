import { ChangeEvent, FC, useEffect, useState } from "react";
import { CloseButton, EmailInput, FormContainer, Info, InputContainer, Overlay, Popup, PopupText, PopupTitle, StyledSubmitButton, SubscribeButton, Wrapper } from "./styles.js";
import Success from "../../Messages/success-message";
import Error from "../../Messages/error-message";
import { useDispatch, useSelector } from "react-redux";
import EmailIcon  from "assets/images/email_icon.svg";
import Image from "components/Image";
import { RootState } from "redux/types.js";
import { postEmailSubscription, resetSubscription } from "redux/actions";



export function Subscribe() {

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const dispatch = useDispatch();

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const emailIsValid = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  const onSubmit = (email: string) => {
    if (emailIsValid(email)) {
      dispatch(postEmailSubscription(email))
      setSuccessMessage("Thank you for subscribing! You'll get the next newsletter in your inbox.");
    }
    else {
      setErrorMessage('Please enter a valid email address.');
    }
    startTimer();
  }

  const subscribed = useSelector(
    (state: RootState) => state.subscription.subscribed
  );

  useEffect(() => {
    if (subscribed) {
      dispatch(resetSubscription());
    }
  }, [subscribed]);

  const startTimer = () => {
    setTimeout(() => {
      setSuccessMessage(null);
      setErrorMessage(null);
    }, 3000); // 5 seconds
  };

  interface SubmitPopupProps {
    onClose: () => void;
    onSubmit: (email: string) => void;
  }


  const SubmitPopup: FC<SubmitPopupProps> = ({ onClose, onSubmit}) => {

    const [email, setEmail] = useState<string>('');

  
    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
    };
  
    const handleEmailSubmit = () => {
      onSubmit(email);
    };
    
      return (
        <Overlay>
          <Popup>
          <CloseButton onClick={onClose}>&times;</CloseButton>
          <Image src={EmailIcon} alt="emailIcon" width={80} height={80} />
          <PopupTitle>Subscribe Barometer Newsletter</PopupTitle>
          <PopupText>
          Subscribe to our monthly newsletter to get an email with a summary of this data so you can compare app stores on an ongoing basis.
          </PopupText>
          <InputContainer>
            <FormContainer>
                <EmailInput
                  value={email}
                  onChange={handleEmailChange}
                />
                <StyledSubmitButton onClick={handleEmailSubmit}>
                  Subscribe
                </StyledSubmitButton>
              </FormContainer>
                <>
                  <Error error={errorMessage} />
                  <Success success={successMessage} />
                </>
            </InputContainer>
          </Popup>
        </Overlay>
      );
    };

  return (
    <Wrapper>
    <Image src={EmailIcon} alt="emailIcon" width={32} height={32} />
    <Info>
      Subscribe our newsletter to get monthly updated data about app stores and much more.
    </Info>
    <SubscribeButton onClick={handleOpen}>Subscribe</SubscribeButton>
    { isOpen &&
    <SubmitPopup onClose={handleClose} onSubmit={onSubmit}/>}
  </Wrapper>
  );
}
