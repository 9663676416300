import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/types";
import Row from "./components/Row";

export function Rows() {
  const packages = useSelector((state: RootState) => state.apps.packages);

  return (
    <Fragment>
      {packages.map((app: any, index: number) => (
        <Row key={index} index={index + 1} app={app} />
      ))}
    </Fragment>
  );
}
